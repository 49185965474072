import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import RequireAuthentication from './AuthHOC';
import HomePage from '../components/pages/HomePage';
import PharmacySummaryPage from '../components/pages/PharmacySummaryPage';
import PharmacyAccountPage from '../components/pages/PharmacyAccountPage';
import PharmacyOrganizationPage from '../components/pages/PharmacyOrganizationPage';
import PhysicianPage from '../components/pages/PhysicianPage';
import PatientPage from '../components/pages/PatientPage';
import ProductPage from '../components/pages/ProductPage';
import RebateSummaryPage from '../components/pages/RebateSummaryPage';
import PatientCompliancePage from '../components/pages/PatientCompliancePage';
import PatientReenrollmentPage from '../components/pages/PatientReenrollmentPage';
import RefillPatientOutletTransactionPage from '../components/pages/RefillPatientOutletTransactionPage';
import AboutPage from '../components/pages/AboutPage';
import ErrorPage from '../components/pages/ErrorPage';

const AppRouter = () => {
    return (
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <BrowserRouter>
          <Switch>
            <RequireAuthentication path='/' component={HomePage} exact />
            <RequireAuthentication path='/products' component={ProductPage} />
            <RequireAuthentication path='/patients' component={PatientPage} />
            <RequireAuthentication path='/pharmacies-summary' component={PharmacySummaryPage} />
            <RequireAuthentication path='/pharmacies-account' component={PharmacyAccountPage} />
            <RequireAuthentication path='/pharmacies-organization' component={PharmacyOrganizationPage} />
            <RequireAuthentication path='/physicians' component={PhysicianPage} />
            <RequireAuthentication path='/rebate-summary' component={RebateSummaryPage} />
            <RequireAuthentication path='/patient-compliance' component={PatientCompliancePage} />
            <RequireAuthentication path='/patient-reenrollment' component={PatientReenrollmentPage} />
            <RequireAuthentication path='/refill-patient-outlet-transaction' component={RefillPatientOutletTransactionPage} />
            <Route path='/about' component={AboutPage} />
            <Route component={ErrorPage} />
          </Switch>
        </BrowserRouter>
      </div>
    )
}

export default AppRouter;