import apiClient from '../services/apiClient.service.js';
import stores from '../models/index.model';
import moment from 'moment-timezone';

const HCPPath = '/dashboard/hcps';
const enrolledHCPPath = '/dashboard/hcps/enrolled';
const transactingHCPPath = '/dashboard/hcps/transacting';
const dateFormat = 'YYYY-MM-DD';

export default {
  state: {
    enrolledHCPs: {
      list: [],
      count: 0,
      pages: 1,
    },
    hcpPatientsEnrolled: {
      list: [],
      count: 0,
      pages: 1,
    },
    transactingHCPs: {
      list: [],
      count: 0,
      pages: 1,
    },
    hcpDetail: {
      hcp_id: 0,
      hcp_name: '',
      clinic_name: '',
      email: '',
      registration_date: new Date(),
      total_patients_enrolled: 0,
      organization_code: '',
    },
    dailyTotalEnrolledUsers: {
      name: '',
      data: [],
    }
  },
  reducers: {
    setEnrolledHCPs(state, payload) {
      return { ...state, enrolledHCPs: payload };
    },
    setHCPPatientsEnrolled(state, payload) {
      return { ...state, hcpPatientsEnrolled: payload };
    },
    setTransactingHCPs(state, payload) {
      return { ...state, transactingHCPs: payload };
    },
    setHCPDetail(state, payload) {
      return { ...state, hcpDetail: payload };
    },
    setDailyTotalEnrolledUsers(state, payload) {
      return { ...state, dailyTotalEnrolledUsers: payload };
    },
  },
  effects: dispatch => ({
    async getEnrolledHCPList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(enrolledHCPPath, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.hcpStore.setEnrolledHCPs(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Enrolled Doctors: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getTransactingHCPList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(transactingHCPPath, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.hcpStore.setTransactingHCPs(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Transacting Doctors: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getHCPDetail({ hcp_id }, rootState) {
      try {
        const result = await apiClient.get(`${HCPPath}/${hcp_id}`);

        dispatch.hcpStore.setHCPDetail(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Doctors: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getHCPPatientsEnrolledList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        hcp_id = 0,
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(`${HCPPath}/${hcp_id}/patients`, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.hcpStore.setHCPPatientsEnrolled(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Doctors: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getDailyTotalEnrolledUserList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(`${HCPPath}/enrolled/daily`, {
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.hcpStore.setDailyTotalEnrolledUsers({
          name: 'Total Users',
          data: result.data.result.list && result.data.result.list.map(dayItem => ({
              name: dayItem.day,
              y: parseInt(dayItem.total_users, 10)
          }))
        });
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Enrolled Users: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async exportEnrolledHCPList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
      },
      rootState
    ) {
      try {
        let filename = `enrolled-doctors-${(new Date()).toJSON()}.csv`;

        const result = await apiClient.download(enrolledHCPPath, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Enrolled Doctors: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

    async exportTransactingHCPList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
      },
      rootState
    ) {
      try {
        let filename = `transacting-doctors-${(new Date()).toJSON()}.csv`;

        const result = await apiClient.download(transactingHCPPath, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Transacting Doctors: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

  }),
};
