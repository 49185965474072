import React from 'react';
import ReactContentLoader from 'react-content-loader';

const ContentLoader = ({ 
    height = 160, 
    width = 400, 
    speed = 2, 
    children }) => (
    <ReactContentLoader
        height={height}
        width={width}
        speed={speed}
        primaryColor='#f3f3f3'
        secondaryColor='#ecebeb'>
        {children}
    </ReactContentLoader>
);

export default ContentLoader;