import React from 'react';
import {
	Table as MaterialTable, TableBody,
	TableHead, TableCell,
	TableRow, TablePagination,
    withStyles,
    Typography
} from '@material-ui/core';

const TableHeader = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.primary.main,
        // backgroundColor: '#8b1b3f',
        color: theme.palette.common.white,
      fontSize: theme.typography.subheading.fontSize,
    }
}))(TableCell);

class Table extends React.Component {
	state = {
        page: 0,
        rowsPerPage: 10
	}

	onChangePage = (e, page) => {
        this.setState({ page });
    }
    
    onChangeRowsPerPage = e => {
        this.setState({ rowsPerPage: e.target.value });
    }

	render() {
		const { columns, rows, totalCount, showPagination = true } = this.props;
        const { page, rowsPerPage } = this.state;

        if (rows.length > 0)
            return <div>
                <MaterialTable>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableHeader key={index} variant='head'>{column}</TableHeader>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => row)}
                    </TableBody>
                </MaterialTable>
                {showPagination &&
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component='div'
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                        onChangePage={this.onChangePage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage} />
                }
            </div>;
        else 
            return <Typography>
                <i>No data found</i>
            </Typography>;
	}
};

export default Table;