import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../models/index.model';
import queryString from 'query-string';
import * as formatters from '../../utilities/formatters.utility';

// Components
import { Grid, Typography } from '@material-ui/core';
import { DataCard } from '../../components/Card/Card.component';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable.component';

const { DASHBOARD_TYPE } = window.config;

class PatientDetailPage extends React.Component {
  state = {
    previousSearch: '',
    currentPage: 1,
    columns: [
      { Header: 'Transaction ID', accessor: 'transaction_batch_id' },
      {
        Header: 'Transaction Date',
        Cell: props => (
          <span>{formatters.date(props.original.transaction_date)}</span>
        ),
      },
      { Header: `${DASHBOARD_TYPE === 'stayon'? 'Doctor' : 'HCP'} Name`, accessor: 'doctor_name' },
      { Header: 'Clinic Name', accessor: 'clinic_name' },
      { Header: 'Type of Transaction', accessor: 'transaction_type' },
      {
        Header: 'Promo Codes Used',
        Cell: props => (
          <span>
            {props.original.promo_codes &&
              props.original.promo_codes.split(',').map(promo_code => {
                return <div key={promo_code}>{promo_code}</div>;
              })}
          </span>
        ),
      },
      {
        Header: 'Product',
        Cell: props => (
          <span>
            {props.original.sku &&
              props.original.sku.split(',').map(sku => {
                return <div key={sku}>{sku}</div>;
              })}
          </span>
        ),
        show: DASHBOARD_TYPE === 'mycare' ? true : false,
      },
      {
        Header: 'List of Hologram No.',
        Cell: props => (
          <span>
            {props.original.meditags &&
              props.original.meditags.split(',').map(meditag => {
                return <div key={meditag}>{meditag}</div>;
              })}
          </span>
        ),
      },
    ],
  };

  async componentDidMount() {
    const { patient_id } = this.props.match.params;
    await this.props.getPatientDetail({
      patient_id,
    });
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { patient_id } = this.props.match.params;
    const { pathname } = this.props.history.location;
    let params = queryString.parse(this.props.history.location.search) || {};
    await this.props.getPatientTransactionList({
      ...params,
      start_date,
      end_date,
      patient_id,
    });
    if (
      params.page <= this.props.patientStore.patientTransactions.pages &&
      params.page > 0
    ) {
      this.setState({ currentPage: params.page || 1 });
    } else {
      this.props.history.push(
        `${pathname}?page=1&start_date=${start_date}&end_date=${end_date}`
      );
    }
  };

  _openExportModal = () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { patient_id } = this.props.match.params;
    this.props.showPopupModal({
      title: 'Patient Transaction History',
      message: 'Are you sure you want to export this table?',
      actions: [
        {
          label: 'Export',
          onClick: async () => {
            await this.props.exportPatientTransactionList({
              start_date,
              end_date,
              eligible: true,
              patient_id,
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  render() {
    const { patientDetail, patientTransactions } = this.props.patientStore;
    const { columns, currentPage } = this.state;

    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <DataCard
            title="Patient Details"
            style={{ background: '#8b1b3f', height: '100%' }}
          >
            <Grid container spacing={8}>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>Patient ID:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {patientDetail.id}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>Registration Date:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {formatters.date(patientDetail.created_at)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DataCard>
        </Grid>
        <Grid item xs={12}>
          <DataCard
            title="Transaction History"
            style={{ background: '#8b1b3f', height: '100%', margin: '20px' }}
            onExport={() => this._openExportModal()}
            dataCount={patientTransactions.count}
          >
            <PaginatedTable
              data={patientTransactions.list}
              columns={columns}
              totalPages={patientTransactions.pages}
              currentPage={currentPage}
              history={this.props.history}
            />
          </DataCard>
        </Grid>
      </Grid>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ patientStore, filterStore, uiStore }) => {
  return {
    ...patientStore,
    ...filterStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(PatientDetailPage);
