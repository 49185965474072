import {
    GET_PATIENT_SUMMARY,
    GET_MONTHLY_ENROLLED_PATIENTS,
    GET_MONTHLY_TRANSACTING_PATIENTS, 
    DISPLAY_PATIENT_PAGE_ERROR,
    GET_MONTHLY_TRANSACTING_REFILLERS_AND_NEW_PATIENTS
} from '../../constants/action-types';

export const displayPatientSummary = payload => ({
    type: GET_PATIENT_SUMMARY,
    payload
});

export const displayMonthlyEnrolledPatients = payload => ({
    type: GET_MONTHLY_ENROLLED_PATIENTS,
    payload
});

export const displayMonthlyTransactingPatients = payload => ({
    type: GET_MONTHLY_TRANSACTING_PATIENTS,
    payload
});

export const displayMonthlyTrasactingRefillersAndNewPatients = payload => ({
    type: GET_MONTHLY_TRANSACTING_REFILLERS_AND_NEW_PATIENTS,
    payload
});

export const displayPatientError = ({ status, message }) => ({
    type: DISPLAY_PATIENT_PAGE_ERROR,
    payload: {
        status,
        message
    }
});