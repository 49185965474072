import { 
    GET_PRODUCT_SUMMARY, 
    GET_BRAND_MIX, 
    GET_MONTHLY_QUANTITY_SOLD, 
    GET_PRODUCT_TRANSACTION_FEED, 
    DISPLAY_PRODUCT_PAGE_ERROR,
    GET_REGISTRATION_TRANSACTION_REPORT,
    GET_REGISTRATION_TRANSACTION_SUMMARY,
    GET_DISPLAY_RETAILER_REBATE_AND_BOXES
} from '../constants/action-types';
import numeral from 'numeral';

const defaultState = {
    totalQuantitySold:{},
    averageQuantity: {},
    totalTransactionCampaigns: 0,
    totalTransactionBatches: 0,
    brandMix: {},
    monthlyTransactions: [],
    transactionFeed: [],
    retailerRebate: {},
    registrationTransactionReport: [],
    registrationTransactionSummary: [],
    error: {
        status: null,
        message: null
    }
};

const productReducer = (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PRODUCT_SUMMARY:

            return {
                ...state,
                totalQuantitySold: 
                    payload.totalQuantitySold 
                    ? payload.totalQuantitySold : {},
                averageQuantity: 
                    payload.averageQuantity
                    ? payload.averageQuantity
                    : {},
                totalTransactionCampaigns: 
                    payload.totalTransactions 
                    ? payload.totalTransactions.totalTransactionCampaigns 
                    : 0,
                totalTransactionBatches: 
                    payload.totalTransactions 
                    ? payload.totalTransactions.totalTransactionBatches
                    : 0
            };
        case GET_BRAND_MIX:
            return {
                ...state,
                brandMix: {
                    name: 'Quantity Sold in Boxes',
                    colorByPoint: true,
                    data: payload ? payload.map(brandItem => {
                        const boxes = parseInt(brandItem.number_of_boxes, 10);
                        const unit =  boxes ===1 ? 'box' : 'boxes';
                        
                        return {
                            id: brandItem.brand,
                            name: `${brandItem.brand} - ${numeral(boxes).format('0, 0')} ${unit}`,
                            y: boxes
                        };
                    }) : []
                }
            };
        case GET_MONTHLY_QUANTITY_SOLD:
            return {
                ...state,
                monthlyTransactions: {
                    name: 'Quantity Sold in Boxes',
                    data: payload ? payload.map(monthItem => ({
                        name: monthItem.transaction_month,
                        y: parseInt(monthItem.total_quantity_sold, 10)
                    })) : []
                }
            };
        case GET_PRODUCT_TRANSACTION_FEED:
            return {
                ...state,
                transactionFeed: payload
            };
        case GET_REGISTRATION_TRANSACTION_REPORT:
            return {
                ...state,
                registrationTransactionReport: payload
            };
        case GET_REGISTRATION_TRANSACTION_SUMMARY:
            return {
                ...state,
                registrationTransactionSummary: payload
            };
        case GET_DISPLAY_RETAILER_REBATE_AND_BOXES:
            return {
                ...state,
                retailerRebate: {
                    ...payload
            }
         }
        case DISPLAY_PRODUCT_PAGE_ERROR: 
            return {
                ...state,
                error: payload
            };
        default:
            return state;
    }
}

export default productReducer;