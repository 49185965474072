import Warp from 'warp-sdk-js';

/**
 * Actions/Action Creators
 */
import { loginSuccess, loginUnauthorized } from '../session/action-creator';

/**
 * Constants
 */
import { RETRIEVE_USER_SUCCESS, RETRIEVE_USER_FAILED } from '../../constants/action-types'

const retrieveUserSuccess = ({ currentUser, organizationData, userPromotionData }) => ({
    type: RETRIEVE_USER_SUCCESS,
    payload: {
        currentUser,
        organizationData,
        userPromotionData
    }
});

const retrieveUserFailed = () => ({
    type: RETRIEVE_USER_FAILED
});

export function retrieveUser() {
    return async dispatch => {
        const currentUser = await Warp.User.current();

        try {
            if (!currentUser)
                dispatch(retrieveUserFailed());
            else {
                const isDashboardUser = currentUser.get('type') === 'dashboard';

                if (isDashboardUser) {
                    dispatch(retrieveUserSuccess({
                        currentUser,
                        organizationData: null,
                        userPromotionData: null
                    }));
                    dispatch(loginSuccess());
                }
                else {
                    dispatch(loginUnauthorized());
                    localStorage.removeItem(`${process.env.REACT_APP_WARP_API_URL}:x-warp-session-token`);
                    localStorage.removeItem(`${process.env.REACT_APP_WARP_API_URL}:x-warp-user-current`);
                    Warp.User._currentUser = null;
                }
            }
        }
        catch (error) {
            dispatch(retrieveUserFailed());
        }
    }
}