import React from 'react';
import { connect } from 'react-redux';

// Components
import { LineGraph, MultiLineColumnGraph } from '../ui/Chart';
import { Grid } from '@material-ui/core';
import { SummaryCard, DataCard } from '../ui/Card';
import AggregatedValue from '../ui/AggregatedValue';

// Actions
import { getPatientSummary, getMonthlyEnrolledPatients, getMonthlyTransactingPatients, getMonthlyRefillersAndNewPatients } from '../../actions/patient';

class PatientPage extends React.Component {
    componentDidMount() {
        this.getSummary();
        this.getMonthlyEnrolledTimegraph();
        this.getMonthlyTransactingTimegraph();
        this.getMonthlyRefillersAndNewPatients();
    }

    getSummary = () => {
        const { dispatch } = this.props;
        dispatch(getPatientSummary());
    }

    getMonthlyEnrolledTimegraph = () => {
        const { dispatch } = this.props;
        dispatch(getMonthlyEnrolledPatients());
    }

    getMonthlyTransactingTimegraph = () => {
        const { dispatch } = this.props;
        dispatch(getMonthlyTransactingPatients());
    }

    getMonthlyRefillersAndNewPatients = () =>{
        const { dispatch } = this.props;
        dispatch(getMonthlyRefillersAndNewPatients());
    }

    render() {
        const ui = this.props.ui;
        const { 
            totalEnrolledPatients, 
            totalTransactingPatients, 
            totalVisitingPatients, 
            averagePatientVisit, 
            averageQuantityPerTransaction, 
            monthlyEnrolled, 
            monthlyTransacting,
            monthlyTransactionRefillerAndPatient
        } = this.props.patient;

        const {
            selectedBrandIds,
            availableBrands
        } = this.props.filter

        return <Grid
                container 
                direction='row' 
                alignItems='center' 
                justify='space-between' 
                spacing={16}>
            
            <Grid xs={12} item>
                <SummaryCard 
                    title='Patient Summary'
                    subtitle='General information'>
                    
                    <AggregatedValue 
                        value={totalEnrolledPatients} 
                        description='Total Enrolled Patients'
                        isLoading={ui.patientSummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactingPatients} 
                        description='Total Transacting Patients'
                        isLoading={ui.patientSummary.isLoading} />
                    <AggregatedValue 
                        value={totalVisitingPatients} 
                        description='Total Refillers'
                        tooltip='Patients who visited the program more than once'
                        isLoading={ui.patientSummary.isLoading} />

                    <AggregatedValue 
                        value={averagePatientVisit} 
                        description='Average Visits'
                        isLoading={ui.patientSummary.isLoading} />
                    <AggregatedValue 
                        value={averageQuantityPerTransaction} 
                        description='Average Quantity in Boxes'
                        tooltip='Purchased per transaction'
                        isLoading={ui.patientSummary.isLoading} />
                </SummaryCard>
            </Grid>

            <Grid xs={12} item>
                <DataCard 
                    isLoading={ui.monthlyEnrolledPatients.isLoading}
                    title='Monthly Enrolled Patients'
                    subtitle='Number of new patients enrolled per month'>

                    <LineGraph
                        series={monthlyEnrolled}
                        yAxis='Total Patients'
                        xAxis='Period' />
                </DataCard>
            </Grid>

            <Grid xs={12} item>
                <DataCard
                    isLoading={ui.monthlyTransactingPatients.isLoading}
                    title='Monthly Transacting Patients'
                    subtitle='Number of patients transacting per month'>

                    <LineGraph 
                        series={monthlyTransacting}
                        yAxis='Total Patients'
                        xAxis='Period' />
                </DataCard>
            </Grid>
            {selectedBrandIds.length !== 0 ?
            <Grid xs={12} item>
                <DataCard
                    isLoading={ui.monthlyTransactionRefillersAndNewPatients.isLoading}
                    title='Monthly Transacting Refillers And New Patients'>

                    <MultiLineColumnGraph 
                        title={selectedBrandIds && selectedBrandIds.length > 1 ? 'Monthly Transacting For Selected Products' : `Monthly Transacting For ${availableBrands && availableBrands.find(brand => brand.id === selectedBrandIds[0]).name} Patients`}
                        series={monthlyTransactionRefillerAndPatient}
                        yAxis='Total Patients'
                        xAxis='Period' />
                </DataCard>
            </Grid> :
            null
            }
        </Grid>;
    }
}

const mapStateToProps = state => ({
    filter: state.filter,
    ui: state.ui,
    patient: state.patient
});

export default connect(mapStateToProps)(PatientPage);