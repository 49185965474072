import { 
    GET_PRODUCT_SUMMARY, 
    DISPLAY_PRODUCT_PAGE_ERROR, 
    GET_BRAND_MIX, 
    GET_MONTHLY_QUANTITY_SOLD, 
    GET_PRODUCT_TRANSACTION_FEED, 
    GET_REGISTRATION_TRANSACTION_REPORT,
    GET_REGISTRATION_TRANSACTION_SUMMARY,
    GET_DISPLAY_RETAILER_REBATE_AND_BOXES
} from '../../constants/action-types';

export const displayProductSummary = payload => ({
    type: GET_PRODUCT_SUMMARY,
    payload
});

export const displayBrandMix = payload => ({
    type: GET_BRAND_MIX,
    payload
});

export const displayMonthlyTransactions = payload => ({
    type: GET_MONTHLY_QUANTITY_SOLD,
    payload
});

export const displayTransactionFeed = payload => ({
    type: GET_PRODUCT_TRANSACTION_FEED,
    payload
});

export const displayRegistrationTransactionReport = payload => ({
    type: GET_REGISTRATION_TRANSACTION_REPORT,
    payload
});

export const displayRegistrationTransactionSummary = payload => ({
    type: GET_REGISTRATION_TRANSACTION_SUMMARY,
    payload
});

export const displayRetailerRebateAndBoxes = payload => ({
    type: GET_DISPLAY_RETAILER_REBATE_AND_BOXES,
    payload
});

export const displayProductError = ({ status, message }) => ({
    type: DISPLAY_PRODUCT_PAGE_ERROR,
    payload: {
        status,
        message
    }
});