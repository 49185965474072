import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ContentLoader from './ContentLoader';
import { Typography } from '@material-ui/core';

export const Chart = ({ 
    options, 
    title, 
    subtitle, 
    type,
    height = 250 
}) => (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type,
                    height
                },
                title: {
                    text: title
                },
                subtitle: {
                    text: subtitle
                },
                ...options
            }} />
    </div>
);

//TODO: simplify
export const LineGraph = ({
    title = '',
    subtitle = '',
    enableDataLabels = true,
    series,
    height,
    legendLayout = 'horizontal',
    yAxis = 'Values',
    xAxis = ''
}) => {
    if (!series || (series && series.data && series.data.length === 0))
        return <Typography>No data found</Typography>;
    else
        return <Chart
            title={title}
            subtitle={subtitle}
            type='line'
            height={height}
            options={{
                xAxis: {
                    title: { text: xAxis },
                    categories: series && series.data ? series.data.map(transaction => transaction.name) : []
                },
                yAxis: {
                    title: { text: yAxis }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: enableDataLabels
                        }
                    }
                },
                legend: {
                    layout: legendLayout
                },
                series
            }} />
};

export const MultiLineGraph = ({
    title = '',
    subtitle = '',
    enableDataLabels = true,
    series,
    height,
    legendLayout = 'horizontal',
    yAxis = 'Values',
    xAxis = ''
}) => {
    if ((series.box && series.box.length === 0) && (series.rebate && series.rebate.length === 0) )
        return <Typography>No data found</Typography>;
    else
        return <Chart
            title={title}
            subtitle={subtitle}
            type='line'
            height={height}
            options={{
                xAxis: {
                    title: { text: xAxis },
                    categories: series && series.rebate > series.box ? series.rebate && series.rebate.map(rebate => rebate.month) : series.box && series.box.map(box => box.month)
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: enableDataLabels
                        }
                    }
                },
                legend: {
                    layout: legendLayout
                },
                yAxis:{
                    title:{ text: yAxis }
                },
                series: [{
                    name: 'Rebate',
                    data: series && series.rebate && series.rebate.map(rebate => rebate.rebate),
            
                }, {
                    name: 'Box',
                    data: series && series.box && series.box.map(box => parseInt(box.boxes, 10)),
                }]
            }} />
};

export const PieChart = ({
    title = '',
    subtitle = '',
    enableDataLabels = true,
    isLoading = false,
    series,
    height,
    legendLayout = 'vertical'
}) => {
    if (isLoading)
        return <ContentLoader
            height={100}
            width={400}
            speed={1}>
            <circle cx='170' cy='50' r='40' />
        </ContentLoader>;
    else if (!series || (series && series.length === 0))
        return <Typography>No data found</Typography>;
    else
        return <Chart
            title={title}
            subtitle={subtitle}
            type='pie'
            height={height}
            options={{
                tooltip: {
                    pointFormat: '<b>Share: <b>{point.percentage:.2f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: enableDataLabels,
                            format: '<b>{point.id}</b>',
                        },
                        showInLegend: true
                    }
                },
                legend: {
                    layout: legendLayout,
                    align: 'right',
                    verticalAlign: 'middle'
                },
                series
            }} />;
};

export const BarStack = ({
    title = '',
    subtitle = '',
    enableDataLabels = true,
    series,
    height,
    isLoading = false,
    yAxis = 'Values',
    xAxis = ''
}) => {
    if (isLoading)
        return <ContentLoader
            height={100}
            width={400}
            speed={2}>
            <rect x="0" y="5" rx="3" ry="3" width="60" height="3" /> 
            <rect x='70' y='5' rx='3' ry='3' width='300' height='3' /> 

            <rect x="0" y="13" rx="3" ry="3" width="60" height="3" /> 
            <rect x='70' y='13' rx='3' ry='3' width='250' height='3' /> 

            <rect x="0" y="21" rx="3" ry="3" width="60" height="3" /> 
            <rect x='70' y='21' rx='3' ry='3' width='200' height='3' />

            <rect x="0" y="29" rx="3" ry="3" width="60" height="3" /> 
            <rect x='70' y='29' rx='3' ry='3' width='150' height='3' />

            <rect x="0" y="37" rx="3" ry="3" width="60" height="3" /> 
            <rect x='70' y='37' rx='3' ry='3' width='100' height='3' />
        </ContentLoader>;
    else
        return <Chart
            height={height}
            title={title}
            subtitle={subtitle}
            type='bar'
            options={{
                xAxis: {
                    title: { text: xAxis },
                    categories: series && series.data ? series.data.map(item => item.name) : []
                },
                yAxis: {
                    title: { text: yAxis }
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: enableDataLabels
                        }
                    }
                },
                series
            }} />;
};

export const ColumnGraph = ({
    title = '',
    subtitle = '',
    enableDataLabels = true,
    series,
    height,
    yAxis = 'Values',
    xAxis = ''
}) => (
    <Chart
        title={title}
        subtitle={subtitle}
        type='column'  
        options={{
            xAxis: {
                title: { text: xAxis },
                categories: series && series.data ? series.data.map(item => item.name) : []
            },
            yAxis: {
                title: { text: yAxis }
            },
            plotOptions: {
				series: {
					dataLabels: {
						enabled: enableDataLabels
					}
				}
			},
            series
        }} />
);


export const MultiLineColumnGraph = ({
    title = '',
    subtitle = '',
    enableDataLabels = true,
    series,
    height,
    yAxis = 'Values',
    xAxis = ''
}) => {
    if ((series.refiller && series.refiller.data.length ===0) && (series.newPatient && series.newPatient.data.length === 0))
        return <Typography>No data found</Typography>;
    else
        return (<Chart
        title={title}
        subtitle={subtitle}
        type='column'  
        options={{
            xAxis: {
                title: { text: xAxis },
                categories: series && series.month ? series.month : []
            },
            yAxis: {
                title: { text: yAxis },
                min: 0,

            },
            plotOptions: {
				column: {
                    stacking: 'normal',
                    minPointLength:1,
                    dataLabels: {
                        enabled: true
                    }
                }
                
			},
            series: [{
                name: series && series.refiller && series.refiller.name,
                data: series && series.refiller && series.refiller.data,
        
            }, {
                name: series && series.newPatient && series.newPatient.name,
                data: series && series.newPatient && series.newPatient.data,
            }]
        }} />)
    };