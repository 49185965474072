import React from 'react';
import { connect } from 'react-redux';

// Components
import { Grid, TableRow, TableCell, Button, Typography } from '@material-ui/core';
import { DataCard } from '../ui/Card';
import Table from '../ui/Table';
import Dialog from '../ui/Dialog';

// Icons
import {
  SaveAltTwoTone as SaveAlt,
} from '@material-ui/icons';

// Actions
import { downloadPatientComplianceReport } from '../../actions/report';

class PatientCompliancePage extends React.Component {
  state = {
    brandRows: [
      { name: 'Lipitor', id: 1 },
      { name: 'Norvasc', id: 2 },
      { name: 'Azoren', id: 8 },
      { name: 'Caduet', id: 9 },
      { name: 'Cardura', id: 7 },
      { name: 'Lyrica', id: 13 },
      { name: 'Olmetec', id: 11 },
      { name: 'Olmetec Plus', id: 12 },
    ],
    exportDialog: false,
    selectedBrand: {
      name: '',
      id: 1,
    }
  }

  openExportDialog = ({ name, id }) => {
    this.setState({
      exportDialog: true,
      selectedBrand: { name, id }
    });
  }

  downloadReport = brand_id => {
    const { dispatch } = this.props;

    this.setState({ exportDialog: false });
    dispatch(downloadPatientComplianceReport({ brand_id }));
  }

  render() {
    const { brandRows, exportDialog, selectedBrand } = this.state;
    const { ui } = this.props;

    return (
      <Grid spacing={16} container>
        <Dialog
            open={exportDialog}
            content={'Are you sure you want to export data from this brand?'}
            title={`Brand: ${selectedBrand.name}`}>
            <Button 
              color='primary' 
              variant='contained'
              onClick={() => this.downloadReport(selectedBrand.id)}
            >
                Confirm
            </Button>
            <Button 
              color='secondary' 
              variant='contained'
              onClick={() => this.setState({exportDialog: false})}
            >
                Close
            </Button>
        </Dialog>

        <Grid item xs={12}>
          <DataCard
            title="Patient Compliance"
            subtitle="Monthly Report for Compliance Status of patients per brand"
            isLoading={ui.patientCompliance.isLoading}
          >
            <Grid spacing={16} container>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1"><strong>To generate report:</strong></Typography>
                <Typography variant="subtitle2" >
                  <ol>
                    <li>Select <strong>Start Date</strong> and <strong>End Date</strong> Filters</li>
                    <li>Click <strong>Apply Filters</strong></li>
                    <li>Press <strong>Export</strong> Button for brand of analysis</li>
                  </ol>
                </Typography>

                <Typography variant="subtitle1"><strong>Definitions:</strong></Typography>
                <Typography variant="subtitle2">
                  <ul>
                    <li><strong>Refillers</strong> - Patient who have returned to buy medication</li>
                    <li><strong>Non Refillers</strong> - Patients who transacted only once</li>
                    <li><strong>Compliant</strong> - Patients with compliance rate greater than or equal to 100</li>
                    <li><strong>Non compliant</strong> - Patients with compliance rate less than 100</li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Table
                  columns={['Brands', 'Action']}
                  totalCount={brandRows && brandRows.length}
                  showPagination={false}
                  rows={brandRows && brandRows.map((brand, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{brand.name}</TableCell>
                      <TableCell>
                        <Button 
                          color='secondary' 
                          variant='contained'
                          size="small"
                          onClick={() => this.openExportDialog({ ...brand })}
                        >
                          <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
                          Export
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                />
              </Grid>
            </Grid>
          </DataCard>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  report: state.report,
  ui: state.ui,
  filter: state.filter,
});

export default connect(mapStateToProps)(PatientCompliancePage);
