import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../models/index.model';
import queryString from 'query-string';
import * as formatters from '../../utilities/formatters.utility';

// Components
import { DataCard } from '../../components/Card/Card.component';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable.component';

const { DASHBOARD_TYPE } = window.config;

class TransactingPatientPage extends React.Component {
  state = {
    previousSearch: '',
    currentPage: 1,
    columns: [
      { Header: 'Patient ID', accessor: 'patient_id', clickable: true },
      {
        Header: 'Registration Date',
        Cell: props => (
          <span>{formatters.date(props.original.registration_date) || '--'}</span>
        ),
      },
      {
        Header: 'Last Transaction Date',
        Cell: props => (
          <span>{formatters.date(props.original.last_transaction_date) || '--'}</span>
        ),
      },
      { Header: 'Status', accessor: 'status' },
      { Header: 'No. of Boxes Bought', accessor: 'number_of_boxes_bought' },
      { Header: 'No. of Boxes Redeemed', accessor: 'number_of_boxes_redeemed' },
    ],
  };

  async componentDidMount() {
    this.setState({ previousPath: this.props.location.pathname });
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;
    let params = queryString.parse(this.props.history.location.search) || {};
    await this.props.getTransactingPatientList({
      ...params,
      start_date,
      end_date,
    });
    if (
      params.page <= this.props.patientStore.transactingPatients.pages &&
      params.page > 0
    ) {
      this.setState({ currentPage: params.page || 1 });
    } else {
      this.props.history.push(
        `${pathname}?page=1&start_date=${start_date}&end_date=${end_date}`
      );
    }
  };

  _openExportModal = () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    this.props.showPopupModal({
      title: 'Transacting Patients',
      message: 'Are you sure you want to export this table?',
      actions: [
        {
          label: 'Export',
          onClick: async () => {
            await this.props.exportTransactingPatientList({
              start_date,
              end_date,
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  render() {
    const { columns, currentPage } = this.state;
    const { start_date, end_date } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;

    return (
      <DataCard
        title="Transacting Patients"
        style={{ background: '#8b1b3f', height: '100%' }}
        onExport={() => this._openExportModal()}
        dataCount={this.props.patientStore.transactingPatients.count}
      >
        <PaginatedTable
          data={this.props.patientStore.transactingPatients.list}
          columns={columns}
          totalPages={this.props.patientStore.transactingPatients.pages}
          currentPage={currentPage}
          history={this.props.history}
          clickAccessor='patient_id'
          onRowClick={patient_id =>
            this.props.history.push(
              `${pathname}/${patient_id}?page=1&start_date=${start_date}&end_date=${end_date}`
            )
          }
        />
      </DataCard>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ patientStore, filterStore, uiStore }) => {
  return {
    ...patientStore,
    ...filterStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(TransactingPatientPage);
