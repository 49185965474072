import { 
    ADD_FILTERS, CLEAR_FILTERS, 
    GET_BRANDS, GET_PRODUCTS, 
    GET_ACCOUNTS, GET_ORGANIZATIONS, GET_FILTERS 
} from '../constants/action-types';
import moment from 'moment-timezone';

const dateFormat = 'YYYY-MM-DD';

const defaultState = {
    selectedBrandIds: [],
    selectedProductIds: [],
    dateFrom: moment().startOf('year').format(dateFormat),
    dateTo: moment().format(dateFormat),
    selectedOrganizations: [],
    selectedAccounts: [],
    availableBrands: [],
    availableProducts: [],
    retrievedAccounts: [],
    retrievedOrganizations: []
};

const filterReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_FILTERS:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_FILTERS:
            return {
                ...state,
                selectedBrandIds: [],
                selectedProductIds: [],
                dateFrom: moment().startOf('year').format(dateFormat),
                dateTo: moment().format(dateFormat),
                selectedOrganizations: [],
                selectedAccounts: [],
                availableProducts: []
            };
        case GET_FILTERS:
            return {
                ...state,
                availableBrands: state.availableBrands.concat(action.payload.brands),
                retrievedAccounts: state.retrievedAccounts.concat(action.payload.accounts),  
                retrievedOrganizations: state.retrievedOrganizations.concat(action.payload.organizations),  
            };
        case GET_BRANDS:
            return {
                ...state,
                availableBrands: state.availableBrands.concat(action.payload)
            };
        case GET_PRODUCTS:
            return {
                ...state,
                availableProducts: action.payload  
            };
        case GET_ACCOUNTS:
            return { 
                ...state,
                retrievedAccounts: state.retrievedAccounts.concat(action.payload) 
            };
        case GET_ORGANIZATIONS:
            return { 
                ...state,
                retrievedOrganizations: state.retrievedOrganizations.concat(action.payload) 
            };
        default:
            return state;
    }
}

export default filterReducer;