import apiClient from '../services/apiClient.service.js';
import stores from '../models/index.model';
import moment from 'moment-timezone';

const transactionPath = '/dashboard/transactions';
const dateFormat = 'YYYY-MM-DD';

export default {
  state: {
    transactions: {
      list: [],
      count: 0,
      pages: 1,
    },
    purchasedProducts: {
      list: [],
      count: 0,
      pages: 1,
    },
    redeemedProducts: {
      list: [],
      count: 0,
      pages: 1,
    },
    hcpTransactions: {
      list: [],
      count: 0,
      pages: 1,
    },
    hologramImageURL: '',
  },
  reducers: {
    setTransactions(state, payload) {
      return { ...state, transactions: payload };
    },
    setPurchasedProducts(state, payload) {
      return { ...state, purchasedProducts: payload };
    },
    setRedeemedProducts(state, payload) {
      return { ...state, redeemedProducts: payload };
    },
    setHCPTransactions(state, payload) {
      return { ...state, hcpTransactions: payload };
    },
    setHologramImageURL(state, payload) {
      return { ...state, hologramImageURL: payload };
    },
  },
  effects: dispatch => ({
    async getTransactionList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = null,
        doctor = ''
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(transactionPath, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
          format,
          doctor
        });

        dispatch.transactionStore.setTransactions(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Transactions: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getProductList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = null,
        campaign_type = 'purchase',
        meditag = null, // meditag = security_label
        doctor = ''
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(`${transactionPath}/products`, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
          format,
          campaign_type,
          security_label: meditag,
          doctor
        });

        if (campaign_type === 'purchase') {
          dispatch.transactionStore.setPurchasedProducts(result.data.result);
        }
        else {
          dispatch.transactionStore.setRedeemedProducts(result.data.result);
        }
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: `${campaign_type === 'purchase' ? 'Purchased' : 'Redeemed'} Products: Error`,
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getHCPTransactionList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = null,
        hcp_id = 0
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(transactionPath, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
          format,
          hcp_id
        });

        dispatch.transactionStore.setHCPTransactions(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Transactions: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async exportTransactionList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
        doctor= ''
      },
      rootState
    ) {
      try {
        let filename = `transactions-${(new Date()).toJSON()}.csv`;
        const result = await apiClient.download(transactionPath, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
            doctor
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Transactions: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

    async exportProductList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
        campaign_type = 'purchase',
        meditag = null, // meditag = security_label
        doctor = ''
      },
      rootState
    ) {
      try {
        let filename = `products-${(new Date()).toJSON()}.csv`;
        if (campaign_type === 'purchase') {
          filename = `purchased-${filename}`;
        }
        else {
          filename = `redeemed-${filename}`;
        }

        const result = await apiClient.download(`${transactionPath}/products`, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
            campaign_type,
            security_label: meditag,
            doctor
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: `${campaign_type === 'purchase' ? 'Purchased' : 'Redeemed'} Products: Error`,
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

    async exportHCPTransactionList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
        hcp_id = 0
      },
      rootState
    ) {
      try {
        let filename = `hcp-${hcp_id}-transactions-${(new Date()).toJSON()}.csv`;
        const result = await apiClient.download(transactionPath, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
            hcp_id
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Transactions: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

    async getTransactionCampaignHologramImage(
      {
        transaction_batch_id = 0,
        transaction_campaign_id = 0
      },
      rootState
    ) {
      try {
        const result = await apiClient.getFileURL(`${transactionPath}/${transaction_batch_id}/transaction-campaign/${transaction_campaign_id}/hologram-image`);

        dispatch.transactionStore.setHologramImageURL(result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'View Image: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

  }),
};
