import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

/**
 * Pages
 */
import MainPage from '../components/pages/MainPage';
import LoginPage from '../components/pages/LoginPage';
import { retrieveUser } from '../actions/user';

class RequireAuthentication extends Component {
  componentDidMount() {
    this.props.dispatch(retrieveUser());
  }

  render() {
    const { isLoggedIn, path, component } = this.props;

    return isLoggedIn ? (
      <MainPage>
        <Route path={path} component={component} />
      </MainPage>
    ) : (
      <LoginPage />
    );
  }
}

// REDUX
const mapStateToProps = state => {
  return {
    isLoggedIn: state.session && state.session.isLoggedIn,
  };
};

export default connect(
  mapStateToProps
)(RequireAuthentication);
