import React from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core' 
import { version } from '../../../package.json';

const MTHPLogo = 'https://s3-ap-southeast-1.amazonaws.com/connect-my-pfizer-production/assets/images/mthp-logo-blue.png';

const AboutPage = () => (
    <Grid container
        direction={'row'}
        alignItems={'center'}
        justify={'center'}
        style={{ background: '#00539b', height: '100%' }}>
        <Grid item 
            md={3} sm={6} xs={9}>
            <Card>
                <div style={{ padding: "50px 50px 0 50px" }}>
                    <img 
                        src={MTHPLogo}
                        alt='MyTotalHealth Logo' 
                        style={{ width: "100%" }} />
                </div>
                <CardContent>
                    <Grid container
                        direction={'row'}
                        alignItems={'center'}
                        justify={'center'}>
                        <Typography>Version {version}</Typography>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
);

export default AboutPage;