import React, { Fragment } from 'react';

import { Typography, withStyles, Grid, Tooltip } from '@material-ui/core';
import numeral from 'numeral';

import { Link } from 'react-router-dom';

const formatValue = value => {
  // Add comma for values with more than 4 digits
  if (!isNaN(value)) {
    const formattedValue = numeral(value).format('0, 0');
    return formattedValue;
  }

  return value;
};

const AggregatedValue = ({
  isLoading = false,
  value = 0,
  valueInBoxes = false,
  link = null,
  description = '',
  classes,
  tooltip = '',
  md = 4,
}) => (
  <Grid item xs={12} md={md}>
    {link ? (
      <Link to={link} style={{ textDecoration: 'none' }}>
        <Typography className={classes.value}>
          {formatValue(value, valueInBoxes)}
        </Typography>
        <Tooltip title={tooltip} placement="bottom-start">
          <Typography className={classes.description}>{description}</Typography>
        </Tooltip>
      </Link>
    ) : (
      <Fragment>
        <Typography className={classes.value}>
          {formatValue(value, valueInBoxes)}
        </Typography>
        <Tooltip title={tooltip} placement="bottom-start">
          <Typography className={classes.description}>{description}</Typography>
        </Tooltip>
      </Fragment>
    )}
  </Grid>
);

const styles = theme => ({
  value: {
    ...theme.typography.headline,
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  description: {
    ...theme.typography.caption,
    textTransform: 'uppercase',
  },
});

export default withStyles(styles)(AggregatedValue);
