import Request from './request';

const route = 'organizations';

export default class Organization {
    static getTotalEnrolled = async filters => {
        try {
            const response = await Request.get(`/${route}/total-enrolled-organizations`, filters);
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
    
    static getTotalTransacting = async filters => {
        try {
            const response = await Request.get(`/${route}/total-transacting-organizations`, filters);
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getLeaderboard = async (filters, userType) => {
        try {
            const response = await Request.get(`/${route}/leaderboard`, filters, { user_type: userType });
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getOrganizationSummary = async (filters, userType) => {
        try {
            const response = await Request.get(`/${route}/organization-summary`, filters, { user_type: userType });
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getMonthlyTransactions = async (filters, branch) => {
        try {
            const response = await Request.get(`/${route}/monthly-transactions-by-organization`, filters, { 
                organization_branch: branch 
            });
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
};