import React from 'react';
import { connect } from 'react-redux';

// Components
import AggregatedValue from '../ui/AggregatedValue';
import { Grid} from '@material-ui/core';
import { SummaryCard } from '../ui/Card';

// Action Creators
import { 
    getPharmacySummary, 
} from '../../actions/hcp';


class PharmacyPage extends React.Component {

     componentDidMount() {
        this.getSummary();
    }

    getSummary = () => {
        const { dispatch } = this.props;
        dispatch(getPharmacySummary());
    }
    
    render() {
        const { ui, hcp } = this.props;
        const {
            totalEnrolledPharmacyAccounts,
            totalTransactingPharmacyAccounts,

            totalEnrolledPharmacyOrganizations,
            totalTransactingPharmacyOrganizations,

            totalEnrolledPharmacists,
            totalTransactingPharmacists,

            totalTransactionBatches,
            totalTransactionCampaigns,
            totalQuantitySold,
        } = hcp.pharmacy;

        return <Grid spacing={16} container>
            
            <Grid item xs={12}>
                <SummaryCard 
                    title='Pharmacy Summary'
                    subtitle='General information about pharmacies'>
                    
                    <AggregatedValue
                        value={totalEnrolledPharmacyAccounts} 
                        description='Total Enrolled Accounts'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue
                        value={totalTransactingPharmacyAccounts} 
                        description='Total Transacting Accounts'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue
                        value={totalTransactionBatches} 
                        description='Total Transaction Batches'
                        isLoading={ui.pharmacySummary.isLoading} />
                    
                    <AggregatedValue
                        value={totalEnrolledPharmacyOrganizations} 
                        description='Total Enrolled Organizations'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactingPharmacyOrganizations} 
                        description='Total Transacting Organizations'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactionCampaigns} 
                        description='Total Transaction Campaigns'
                        isLoading={ui.pharmacySummary.isLoading} />

                    <AggregatedValue 
                        value={totalEnrolledPharmacists} 
                        description='Total Enrolled Pharmacists'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactingPharmacists} 
                        description='Total Transacting Pharmacists'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue 
                        value={totalQuantitySold && totalQuantitySold.box} 
                        description='Total Boxes Sold'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue 
                        value={totalQuantitySold && totalQuantitySold.strips} 
                        description='Total Strips Sold'
                        isLoading={ui.pharmacySummary.isLoading} />
                </SummaryCard>
                
            </Grid>
        </Grid>;
    }
};

const mapStateToProps = state => ({
    filter: state.filter,
    ui: state.ui,
    hcp: state.hcp
});

export default connect(mapStateToProps)(PharmacyPage);