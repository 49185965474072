import React, { Fragment } from 'react';
import {
    Dialog as MaterialDialog, DialogTitle as MaterialDialogTitle, 
    DialogContent, DialogContentText, 
    DialogActions,
    Button, withStyles
} from '@material-ui/core';

const Dialog = ({ open, title = 'MyTotalHealth', content, children, onSubmit, onClose }) => (
    <MaterialDialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                {content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {children ? children : 
            <Fragment>
                <Button onClick={onClose} color='error'>
                    Close
                </Button>
                <Button onClick={onSubmit} color='primary'>
                    Agree
                </Button>    
            </Fragment>}
        </DialogActions>
    </MaterialDialog>
);

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#E0E0E0',
        marginBottom: 40
    }
}))(MaterialDialogTitle);


export default Dialog;