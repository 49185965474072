import { API_REQUEST } from '../constants/action-types';

export const apiRequestStart = trigger => ({
    type: API_REQUEST,
    payload: {
        [trigger]: { isLoading: true }
    }
});

export const apiRequestEnd = trigger => ({
    type: API_REQUEST,
    payload: {
        [trigger]: { isLoading: false }
    }
});