import React from 'react';
import { connect } from 'react-redux';

// Components
import { Grid, TableRow, TableCell, Tooltip, Button } from '@material-ui/core';
import {  DataCard } from '../ui/Card';
import Dialog from '../ui/Dialog';
import Table from '../ui/Table';
import { BarStack, ColumnGraph } from '../ui/Chart';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Action Creators
import { 
    getAccountLeaderboard, 
    getAccountSummary,
    getMonthlyTransactionsByAccount,
} from '../../actions/hcp';

const userType = 'pharmacist';

class PharmacyPage extends React.Component {
    state = {
        accountDialog: false,
        accountNumber: null,
        accountName: null,
    }

     componentDidMount() {   
        this.getAccountLeaderboard();
        this.getAccountSummary();
    }

    getAccountLeaderboard = () => {
        const { dispatch } = this.props;
        dispatch(getAccountLeaderboard(userType));
    }

    getAccountSummary = () => {
        const { dispatch } = this.props;
        dispatch(getAccountSummary(userType));
    }

    getAccountColumns = () => [
        'Account',
        'Total Transacting Organizations',
        'Total Boxes Sold',
        'Total Transacting Patients',
        'Total Transactions',
        'Action'
    ]

    getAccountTransactions = (accountNumber, accountName) => {
        const { dispatch } = this.props;
        dispatch(getMonthlyTransactionsByAccount(accountNumber));

        this.setState({ 
            accountNumber, 
            accountDialog: true,
            accountName
        });
    }

    displayTransactions = () => {
        const monthlyTransactions = this.props.hcp.monthlyTransactions;

        return <div>
            <ColumnGraph
                xAxis='Period'
                yAxis='Total Transactions'
                height={350}
                series={monthlyTransactions} />
        </div>;
    }

    close = dialogType => {
        this.setState({ 
            [dialogType]: false, 
            accountNumber: null, 
            accountName: null,
        });
    }
    
    render() {
        const { accountDialog, accountName } = this.state;
        const { ui, hcp } = this.props;
        const {
            pharmacyAccountLeaderboards,
            pharmacyAccounts,
        } = hcp.pharmacy;

        return <Grid spacing={16} container>

            <Grid item xs={12}>
                <DataCard
                    title='Account Leaderboard'
                    subtitle='Top Selling Pharmacy Accounts'>

                    <BarStack
                        xAxis='Accounts'
                        yAxis='Boxes Sold'
                        height={350}
                        isLoading={ui.accountLeaderboard.isLoading}
                        series={pharmacyAccountLeaderboards} />
                </DataCard>
            </Grid>

            
            <Grid item xs={12}>
                <DataCard
                    title='Pharmacy Accounts View'
                    subtitle='Table summary of each pharmacy account'
                    isLoading={ui.accountsView.isLoading}>

                    <Table
                        columns={this.getAccountColumns()}
                        totalCount={pharmacyAccounts.length}
                        rows={pharmacyAccounts.map((row, index) => (
                            <TableRow key={index} hover>
                                <TableCell>{row.account_name}</TableCell>
                                <TableCell>{row.total_transacting_organizations}</TableCell>
                                <TableCell numeric>{row.total_quantity_sold}</TableCell>
                                <TableCell numeric>{row.total_transacting_patients}</TableCell>
                                <TableCell numeric>{row.total_transactions}</TableCell>
                                <TableCell>
                                    <Tooltip title='View monthly transactions'>
                                        <Button 
                                            color='secondary' 
                                            variant='contained' 
                                            onClick={() => this.getAccountTransactions(row.account_number, row.account_name)}>
                                            <VisibilityIcon />
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))} />
                </DataCard>
            </Grid>

            <Dialog
                open={accountDialog}
                content={this.displayTransactions()}
                title={`Monthly Transactions by ${accountName}`}>
                <Button 
                    onClick={() => this.close('accountDialog')} 
                    color='primary' 
                    variant='contained'>
                    Close
                </Button>
            </Dialog>
        </Grid>;
    }
};

const mapStateToProps = state => ({
    filter: state.filter,
    ui: state.ui,
    hcp: state.hcp
});

export default connect(mapStateToProps)(PharmacyPage);