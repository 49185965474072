import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../models/index.model';
import queryString from 'query-string';
import * as formatters from '../../utilities/formatters.utility';

// Components
import { DataCard } from '../../components/Card/Card.component';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable.component';
import { LineGraph } from '../../../common/components/Chart/Chart.component';

const { DASHBOARD_TYPE } = window.config;

class EnrolledDoctorPage extends React.Component {
  state = {
    previousSearch: '',
    currentPage: 1,
    columns: [
      { Header: 'HCP ID', accessor: 'hcp_id' },
      {
        Header: 'User Type',
        accessor: 'user_type',
        show: DASHBOARD_TYPE === 'mycare' ? true : false
      },
      {
        Header: 'Registration Date',
        Cell: props => (
          <span>{formatters.date(props.original.registration_date)}</span>
        ),
      },
      { Header: 'HCP Name', accessor: 'hcp_name', clickable: true },
      { Header: DASHBOARD_TYPE === 'mycare' ? 'DKSH Number' : 'Zuellig Account Number', accessor: 'organization_code'},
      { Header: 'Clinic Name', accessor: 'clinic_name' },
      { Header: 'Email', accessor: 'email' },
      {
        Header: 'No. of Patients Enrolled',
        accessor: 'total_patients_enrolled',
      },
    ],
  };

  async componentDidMount() {
    this.setState({ previousPath: this.props.location.pathname });
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date ||
      prevProps.filterStore.filters.doctor !== this.props.filterStore.filters.doctor
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;
    let params = queryString.parse(this.props.history.location.search) || {};
    await this.props.getEnrolledHCPList({
      ...params,
      start_date,
      end_date,
    });
    await this.props.getDailyTotalEnrolledUserList({
      start_date,
      end_date,
    });
    if (
      params.page <= this.props.hcpStore.enrolledHCPs.pages &&
      params.page > 0
    ) {
      this.setState({ currentPage: params.page || 1 });
    } else {
      this.props.history.push(`${pathname}?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`);
    }
  };

  _openExportModal = () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    this.props.showPopupModal({
      title: `Enrolled ${DASHBOARD_TYPE === 'stayon'? 'Doctors' : 'HCPs'}`,
      message: 'Are you sure you want to export this table?',
      actions: [
        {
          label: 'Export',
          onClick: async () => {
            await this.props.exportEnrolledHCPList({
              start_date,
              end_date,
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  render() {
    const { columns, currentPage } = this.state;
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;

    return (
      <div>
        <DataCard
          title={`Enrolled ${DASHBOARD_TYPE === 'stayon'? 'Doctors' : 'HCPs'}`}
          style={{ background: '#8b1b3f', height: '100%' }}
          onExport={() => this._openExportModal()}
          dataCount={this.props.hcpStore.enrolledHCPs.count}
        >
          <PaginatedTable
            data={this.props.hcpStore.enrolledHCPs.list}
            columns={columns}
            totalPages={this.props.hcpStore.enrolledHCPs.pages}
            currentPage={currentPage}
            history={this.props.history}
            clickAccessor='hcp_id'
            onRowClick={hcp_id =>
              this.props.history.push(`${pathname}/${hcp_id}?tpage=1&ppage=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`)
            }
          />
        </DataCard><br />

          <DataCard
            title='Daily User Enrollment'
            subtitle='Number of users enrolled per day'
          >
            <LineGraph
              series={this.props.hcpStore.dailyTotalEnrolledUsers}
              yAxis='Total Users'
              xAxis='Period'
            />
          </DataCard>
      </div>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ hcpStore, filterStore, uiStore }) => {
  return {
    ...hcpStore,
    ...filterStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(EnrolledDoctorPage);
