import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { rematchContext } from '../../models/index.model';

/**
 * Material UI - Core
 */
import {
  Grid,
  FormControl,
  FormLabel,
  Typography,
  Button,
  withStyles,
  Card,
  Divider,
  Chip,
  Avatar,
  Input,
  InputAdornment
} from '@material-ui/core';

/**
 * Material UI - Icon
 */
import {
  FilterList as FilterListIcon,
  Event as EventIcon,
  Label as LabelIcon,
  SaveAltTwoTone as SaveAlt,
  SearchTwoTone as Search,
} from '@material-ui/icons';

/**
 * Custom UI Components
 */
import DatePicker from '../DatePicker/DatePicker.component';


/**
 * Styles
 */
const styles = theme => {
  return {
    sectionLabel: {
      padding: '24px 24px 0 24px',
    },
    tabRoot: {
      minWidth: 30,
    },
    formLabelRoot: {
      marginBottom: 8 * 2,
      textTransform: 'uppercase',
    },
  };
};

function TabContainer(props) {
  return (
    <Grid container style={{ padding: 8 * 3 }}>
      {props.children}
    </Grid>
  );
}

const dateFormat = 'YYYY-MM-DD';

const { DASHBOARD_TYPE } = window.config;
class Filter extends Component {
  state = {
    dateFrom: moment()
      .subtract(2, 'months')
      .format(dateFormat),
    dateTo: moment().format(dateFormat),
    doctor: '',
    previousSearch: '',
  };

  componentDidMount() {
    // const { dateFrom, dateTo, doctor} = this.state;
    // !this.props.history.location.search && this.props.history.push(
    //   `${
    //     this.props.location.pathname
    //   }?&start_date=${dateFrom}&end_date=${dateTo}${doctor ? `&doctor=${doctor}` : ''}`
    // );
  }

  componentDidUpdate() {
    const prevSearch = queryString.parse(this.state.previousSearch);
    let { start_date, end_date, doctor} = queryString.parse(
      this.props.history.location.search
    );
    // CHECK date query params changes
    if (
      prevSearch.start_date !== start_date ||
      prevSearch.end_date !== end_date || prevSearch.doctor !== doctor){
      this.setState({
        previousSearch: this.props.history.location.search,
        dateFrom: moment(start_date).format(dateFormat),
        dateTo: moment(end_date).format(dateFormat),
      });
      this._loadFilters(start_date, end_date, doctor);
    }
  }

  _loadFilters = async (start_date, end_date, doctor = '') => {
    await this.props.applyFilters({
      start_date: moment(start_date).format(dateFormat),
      end_date: moment(end_date).format(dateFormat),
      doctor
    });
  };

  onChangeDateFrom = momentDate =>
    this.setState({
      dateFrom: momentDate.format(dateFormat),
    });
  onChangeDateTo = momentDate =>
    this.setState({
      dateTo: momentDate.format(dateFormat),
    });

  // Submit Set Filters
  onSubmit = e => {
    e.preventDefault();
    this._applySetFilters();
  };

  // Clear/reset filters
  onClear = async e => {
    e.preventDefault();
    await this.props.applyFilters({});
    this.setState({
      dateFrom: moment()
        .subtract(2, 'months')
        .format(dateFormat),
      dateTo: moment().format(dateFormat),
    });
    this._applySetFilters();
  };

  // For Applying Filters
  _applySetFilters = () => {
    const { dateFrom, dateTo, doctor } = this.state;
    const params = queryString.parse(this.props.history.location.search);
    delete params.start_date;
    delete params.end_date;
    delete params.doctor;
    
    this.setState({ doctor: ''});
    const stringParams = queryString.stringify(params);
    this.props.history.push(
      `${
        this.props.location.pathname
      }?${stringParams}&start_date=${dateFrom}&end_date=${dateTo}${doctor ? `&doctor=${doctor}` : ''}`
    );
  };

  _removeMeditagFilter = () => {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const params = queryString.parse(this.props.history.location.search);
    delete params.start_date;
    delete params.end_date;
    delete params.meditag;
    delete params.doctor;
    const stringParams = queryString.stringify(params);

    this.props.history.push(
      `${
        this.props.location.pathname
      }?${stringParams}&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ''}`
    );
  };

  _removeDoctorFilter = () => {
    const { start_date, end_date} = this.props.filterStore.filters;
    const params = queryString.parse(this.props.history.location.search);
    delete params.start_date;
    delete params.end_date;
    delete params.doctor;
    const stringParams = queryString.stringify(params);
    this.setState({ doctor: ''});
    this.props.history.push(
      `${
        this.props.location.pathname
      }?${stringParams}&start_date=${start_date}&end_date=${end_date}`
    );
  };

  render() {
    const { classes } = this.props;
    const { dateFrom, dateTo } = this.state;
    const maxDate = moment(dateTo, dateFormat)
      .subtract(1, 'day')
      .format(dateFormat);
    const minDate = moment(dateFrom, dateFormat)
      .add(1, 'day')
      .format(dateFormat);
    const params = queryString.parse(this.props.history.location.search);


    const ActiveFilters = () => (
      <Grid container style={{ padding: 8 * 3 }}>
        <FormControl>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            <Typography variant="body2" color="secondary">
              Active Filters
            </Typography>
          </FormLabel>
          <Grid item xs={12}>
            <Chip
              avatar={
                <Avatar>
                  <EventIcon />
                </Avatar>
              }
              clickable={false}
              label={
                //'Timeframe' +
                //' ' +
                this.props.filterStore.filters.start_date +
                ' to ' +
                this.props.filterStore.filters.end_date
              }
              style={{ marginTop: 8, marginRight: 8 }}
              onDelete={
                this.props.filterStore.filters.start_date ===
                  moment()
                    .subtract(2, 'months')
                    .format(dateFormat) &&
                this.props.filterStore.filters.end_date ===
                  moment().format(dateFormat)
                  ? null
                  : this.onClear
              }
              color="secondary"
            />
            {
              this.props.filterStore.filters.doctor && (
                <Chip
                avatar={
                  <Avatar>
                    <LabelIcon />
                  </Avatar>
                }
                clickable={false}
                style={{ marginTop: 8, marginRight: 8 }}
                label={'Doctor: ' + this.props.filterStore.filters.doctor}
                onDelete={this._removeDoctorFilter}
                color="secondary"
              />
              )
            }
            {params.meditag && (
              <Chip
                avatar={
                  <Avatar>
                    <LabelIcon />
                  </Avatar>
                }
                clickable={false}
                style={{ marginTop: 8, marginRight: 8 }}
                label={'Meditag: ' + params.meditag}
                onDelete={this._removeMeditagFilter}
                color="secondary"
              />
            )}
          </Grid>
        </FormControl>
      </Grid>
    );

    return (
      <Card>
        <Grid container direction="row" spacing={16}>
          <Grid item xs={12}>
            <ActiveFilters />
            <Divider />
            <TabContainer>
              <FormControl style={{ width: '100%' }}>
                <FormLabel classes={{ root: classes.formLabelRoot }}>
                  <Typography variant="body2" color="secondary">
                    Timeframe
                  </Typography>
                </FormLabel>
                <DatePicker
                  fullWidth={false}
                  label="From"
                  maxDate={maxDate}
                  name="date"
                  onChange={this.onChangeDateFrom}
                  style={{ marginBottom: 16 }}
                  value={dateFrom}
                />

                <DatePicker
                  fullWidth={false}
                  label="To"
                  minDate={minDate}
                  name="date"
                  onChange={this.onChangeDateTo}
                  value={dateTo}
                  style={{ marginBottom: 16 }}
                />
              </FormControl>
              {DASHBOARD_TYPE === 'stayon' ? <Input
                fullWidth={true}
                placeholder={'Search Doctor'}
                value={this.state.doctor}
                onChange={e => this.setState({ doctor: e.target.value })}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              /> : null
              }
            </TabContainer>
            <Grid container spacing={8} style={{ padding: 8 * 3 }}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={this.onSubmit}
                  variant="contained"
                >
                  <FilterListIcon
                    fontSize="small"
                    style={{ marginRight: 6 }}
                  />
                  Apply Filters
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ filterStore, uiStore }) => {
  return {
    ...filterStore,
    ...uiStore,
  };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToPropsRematch,
      mapDispatchToPropsRematch,
      null,
      { context: rematchContext }
    )(Filter)
  )
);
