import React from 'react';
import { connect } from 'react-redux';

// Components
import { Grid, Button, Typography } from '@material-ui/core';
import { DataCard } from '../ui/Card';
import Dialog from '../ui/Dialog';

// Icons
import {
  SaveAltTwoTone as SaveAlt,
} from '@material-ui/icons';

// Actions
import { downloadRebateSummaryReport } from '../../actions/report';

class RebateSummaryPage extends React.Component {
  state = {
    exportDialog: false,
  }

  openExportDialog = () => {
    this.setState({ exportDialog: true });
  }

  downloadReport = () => {
    const { dispatch } = this.props;

    this.setState({ exportDialog: false });
    dispatch(downloadRebateSummaryReport());
  }

  render() {
    const { exportDialog } = this.state;
    const { ui } = this.props;

    return (
      <Grid spacing={16} container>
        <Dialog
            open={exportDialog}
            content={'Are you sure you want to export data?'}
            title={`Rebate Summary`}>
            <Button 
              color='primary' 
              variant='contained'
              onClick={() => this.downloadReport()}
            >
                Confirm
            </Button>
            <Button 
              color='secondary' 
              variant='contained'
              onClick={() => this.setState({exportDialog: false})}
            >
                Close
            </Button>
        </Dialog>

        <Grid item xs={12}>
          <DataCard
            title="Rebate Summary"
            subtitle="Monthly Report for Rebates Processed Per Account"
            actionButton={{
              text: 'Export',
              iconComponent: <SaveAlt fontSize="small" style={{ marginRight: 6 }} />,
              action: () => this.openExportDialog()
            }}
            isLoading={ui.rebateSummary.isLoading}
          >
            <Typography variant="subtitle1"><strong>To generate report:</strong></Typography>
            <Typography variant="subtitle2" >
              <ol>
                <li>Select <strong>Start Date</strong> and <strong>End Date</strong> Filters</li>
                <li>Select <strong>Brand</strong> Filter</li>
                <li>Select <strong>Product</strong> Filter</li>
                <li>Select <strong>Account</strong> Filter</li>
                <li>Click <strong>Apply Filters</strong></li>
                <li>Press <strong>Export</strong> Button</li>
              </ol>
            </Typography>
          </DataCard>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  report: state.report,
  ui: state.ui,
  filter: state.filter,
});

export default connect(mapStateToProps)(RebateSummaryPage);
