import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../models/index.model';
import queryString from 'query-string';
import * as formatters from '../../utilities/formatters.utility';

// Components
import { Grid, Typography } from '@material-ui/core';
import { DataCard } from '../../components/Card/Card.component';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable.component';

const { DASHBOARD_TYPE } = window.config;

class DoctorDetailPage extends React.Component {
  state = {
    previousSearch: '',
    currentPpage: 1,
    currentTpage: 1,
    patientColumns: [
      { Header: 'Patient ID', accessor: 'patient_id' },
      {
        Header: 'Registration Date',
        Cell: props => (
          <span>{formatters.date(props.original.registration_date)}</span>
        ),
      },
    ],
    transactionColumns: [
      {
        Header: 'Date and Time of Transaction',
        Cell: props => (
          <span>{formatters.date(props.original.transaction_date)}</span>
        ),
      },
      { Header: 'Transaction ID', accessor: 'transaction_batch_id' },
      { Header: `${DASHBOARD_TYPE === 'stayon'? 'Doctor' : 'HCP'} Name`, accessor: 'doctor_name' },
      { Header: 'Clinic Name', accessor: 'clinic_name' },
      { Header: 'Patient ID', accessor: 'patient_id' },
      { Header: 'Promo Code Used', accessor: 'promo_code' },
      { Header: 'No. of Boxes', accessor: 'number_of_boxes' },
      {
        Header: 'Meditags Used',
        Cell: props => (
          <span>
            {props.original.meditags_used &&
              props.original.meditags_used.split(',').map(meditag => {
                return <div key={meditag}>{meditag}</div>;
              })}
          </span>
        ),
      },
    ],
  };

  async componentDidMount() {
    const { hcp_id } = this.props.match.params;
    await this.props.getHCPDetail({
      hcp_id,
    });
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { hcp_id } = this.props.match.params;
    const { pathname } = this.props.history.location;
    let params = queryString.parse(this.props.history.location.search) || {};
    await this.props.getHCPTransactionList({
      ...params,
      page: params.tpage || 1,
      start_date,
      end_date,
      hcp_id,
    });
    await this.props.getHCPPatientsEnrolledList({
      ...params,
      page: params.ppage || 1,
      start_date,
      end_date,
      hcp_id,
    });

    // Check if ppage is less than 1 or greater than its total pages
    if (
      params.ppage <= this.props.hcpStore.hcpPatientsEnrolled.pages &&
      params.ppage > 0
    ) {
      this.setState({ currentPpage: params.ppage || 1 });
    } else {
      this.props.history.push(
        `${pathname}?tpage=${
          params.tpage
        }&ppage=1&start_date=${start_date}&end_date=${end_date}`
      );
    }

    // Check if tpage is less than 1 or greater than its total pages
    if (
      params.tpage <= this.props.transactionStore.hcpTransactions.pages &&
      params.tpage > 0
    ) {
      this.setState({ currentTpage: params.tpage || 1 });
    } else {
      this.props.history.push(
        `${pathname}?tpage=1&ppage=${
          params.ppage
        }&start_date=${start_date}&end_date=${end_date}`
      );
    }
  };

  _openExportModal = () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { hcp_id } = this.props.match.params;
    this.props.showPopupModal({
      title: `${DASHBOARD_TYPE === 'stayon'? 'Doctor' : 'HCP'} Transaction History`,
      message: 'Are you sure you want to export this table?',
      actions: [
        {
          label: 'Export',
          onClick: async () => {
            await this.props.exportHCPTransactionList({
              start_date,
              end_date,
              hcp_id,
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  render() {
    const { hcpDetail, hcpPatientsEnrolled } = this.props.hcpStore;
    const { hcpTransactions } = this.props.transactionStore;
    const {
      transactionColumns,
      patientColumns,
      currentTpage,
      currentPpage,
    } = this.state;
    const { start_date, end_date } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;

    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <DataCard
            title={`${DASHBOARD_TYPE === 'stayon'? 'Doctor' : 'HCP'} Details`}
            style={{ background: '#8b1b3f', height: '100%' }}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} container spacing={8}>
                <Grid item md={6} lg={4} xl={3} container>
                  <Grid item xs={4} lg={5} xl={4}>
                    <Typography>HCP ID:</Typography>
                  </Grid>
                  <Grid item xs={8} lg={7} xl={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {hcpDetail.hcp_id}
                    </Typography>
                  </Grid>
                </Grid>
                {DASHBOARD_TYPE === 'mycare' &&
                <Grid item md={6} lg={4} xl={3} container>
                  <Grid item xs={4} lg={5} xl={4}>
                    <Typography>User Type:</Typography>
                  </Grid>
                  <Grid item xs={8} lg={7} xl={8}>
                    <Typography style={{ fontWeight: 700 }}>
                      {hcpDetail.user_type}
                    </Typography>
                  </Grid>
                </Grid>
                }
                <Grid item md={6} lg={4} xl={3} container>
                  <Grid item xs={4} lg={5} xl={4}>
                    <Typography>HCP Full Name:</Typography>
                  </Grid>
                  <Grid item xs={8} lg={7} xl={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {hcpDetail.hcp_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={6} lg={4} xl={3} container>
                  <Grid item xs={4} lg={5} xl={4}>
                    <Typography>Clinic Name:</Typography>
                  </Grid>
                  <Grid item xs={8} lg={7} xl={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {hcpDetail.clinic_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={6} lg={4} xl={3} container>
                  <Grid item xs={4} lg={5} xl={4}>
                    <Typography>Email Address:</Typography>
                  </Grid>
                  <Grid item xs={8} lg={7} xl={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {hcpDetail.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={6} lg={4} xl={3} container>
                  <Grid item xs={4} lg={5} xl={4}>
                    <Typography>{DASHBOARD_TYPE === 'mycare' ? 'DKSH' : 'Zuellig'} Code:</Typography>
                  </Grid>
                  <Grid item xs={8} lg={7} xl={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {hcpDetail.organization_code}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={6} lg={4} xl={3} container>
                  <Grid item xs={4} lg={5} xl={4}>
                    <Typography>Registration Date:</Typography>
                  </Grid>
                  <Grid item xs={8} lg={7} xl={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {formatters.date(hcpDetail.registration_date)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={6} lg={6} container>
                  <Grid item xs={4} lg={5} xl={4}>
                    <Typography>Number of Patients Enrolled:</Typography>
                  </Grid>
                  <Grid item xs={8} lg={7} xl={8}>
                    <Typography
                      style={{ fontWeight: 700, wordWrap: 'break-word' }}
                    >
                      {hcpDetail.total_patients_enrolled}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DataCard>
        </Grid>
        <Grid item xs={12}>
          <DataCard
            title="Patients Enrolled"
            style={{ background: '#8b1b3f', height: '100%', margin: '20px' }}
          >
            <PaginatedTable
              data={hcpPatientsEnrolled.list}
              columns={patientColumns}
              totalPages={hcpPatientsEnrolled.pages}
              currentPage={currentPpage}
              pageIdentifier="ppage"
              history={this.props.history}
              clickAccessor="patient_id"
              onRowClick={patient_id =>
                this.props.history.push(
                  `${pathname}/patient/${patient_id}?page=1&start_date=${start_date}&end_date=${end_date}`
                )
              }
            />
          </DataCard>
        </Grid>
        <Grid item xs={12}>
          <DataCard
            title="Transaction History"
            style={{ background: '#8b1b3f', height: '100%', margin: '20px' }}
            onExport={() => this._openExportModal()}
            dataCount={hcpTransactions.count}
          >
            <PaginatedTable
              data={hcpTransactions.list}
              columns={transactionColumns}
              totalPages={hcpTransactions.pages}
              currentPage={currentTpage}
              pageIdentifier="tpage"
              history={this.props.history}
            />
          </DataCard>
        </Grid>
      </Grid>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({
  hcpStore,
  transactionStore,
  filterStore,
  uiStore,
}) => {
  return {
    ...hcpStore,
    ...transactionStore,
    ...filterStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(DoctorDetailPage);
