// Classes
import Patient from '../../classes/patient';
import Product from '../../classes/product';

// Action Creators
import { apiRequestStart, apiRequestEnd } from '../ui';
import { displayPatientError, displayPatientSummary, displayMonthlyEnrolledPatients, displayMonthlyTransactingPatients, displayMonthlyTrasactingRefillersAndNewPatients } from './action-creators';

export const getPatientSummary = () => {
    return async (dispatch, getState) => {
        // Start loading
        dispatch(apiRequestStart('patientSummary'));

        const filters = getState().filter;
        
        try {
            const totalEnrolledPatients = await Patient.getTotalEnrolled(filters);
            const totalTransactingPatients = await Patient.getTotalTransacting(filters);
            const totalVisitingPatients = await Patient.getTotalVisiting(filters);
            const averagePatientVisit = await Patient.getAveragePatientVisits(filters);
            const averageQuantityPerTransaction = await Product.getAverageQuantity(filters);

            dispatch(displayPatientSummary({
                totalEnrolledPatients,
                totalTransactingPatients,
                totalVisitingPatients,
                averagePatientVisit,
                averageQuantityPerTransaction
            }));
        }
        catch (e) {
            dispatch(displayPatientError({
                status: e.status,
                message: e.message
            }));
        }

        // Stop loading
        dispatch(apiRequestEnd('patientSummary'));
    };
};

export const getMonthlyEnrolledPatients = () => {
    return async (dispatch, getState) => {
        // Start loading
        dispatch(apiRequestStart('monthlyEnrolledPatients'));

        const filters = getState().filter;
        
        try {
            const result = await Patient.getMonthlyEnrolledGraph(filters);
            dispatch(displayMonthlyEnrolledPatients(result));
        }
        catch (e) {
            dispatch(displayPatientError({
                status: e.status,
                message: e.message
            }));
        }

        // Stop loading
        dispatch(apiRequestEnd('monthlyEnrolledPatients'));
    };
};

export const getMonthlyTransactingPatients = () => {
    return async (dispatch, getState) => {
        // Start loading
        dispatch(apiRequestStart('monthlyTransactingPatients'));

        const filters = getState().filter;
        
        try {
            const result = await Patient.getMonthlyTransactingGraph(filters);
            dispatch(displayMonthlyTransactingPatients(result));
        }
        catch (e) {
            dispatch(displayPatientError({
                status: e.status,
                message: e.message
            }));
        }

        // Stop loading
        dispatch(apiRequestEnd('monthlyTransactingPatients'));
    };
};

export const getMonthlyRefillersAndNewPatients = () => {
    return async (dispatch, getState) => {
        // Start loading
        dispatch(apiRequestStart('monthlyTransactionRefillersAndNewPatients'));

        const filters = getState().filter;
        
        try {
            const result = await Patient.getMonthlyTransactionRefillersAndPatients(filters);
            dispatch(displayMonthlyTrasactingRefillersAndNewPatients(result));
        }
        catch (e) {
            dispatch(displayPatientError({
                status: e.status,
                message: e.message
            }));
        }

        // Stop loading
        dispatch(apiRequestEnd('monthlyTransactionRefillersAndNewPatients'));
    };
};