export default {
  state: {
    isLoading: false,
    popupAlert: {
      disableCloseButton: false,
      title: 'Popup',
      open: false,
      actions: [],
      message: '',
      onClose: () => {},
    },
  },
  reducers: {
    setLoadingAction(state, payload) {
      return {
        ...state,
        isLoading: payload,
      };
    },
    closePopupModalAction(state, payload) {
      return {
        ...state,
        popupAlert: {
          ...state.popupAlert,
          open: false,
        },
      };
    },
    openPopupModalAction(state, payload) {
      return {
        ...state,
        popupAlert: {
          ...payload,
          open: true,
        },
      };
    },
  },
  effects: dispatch => ({
    showLoading(payload) {
      dispatch.uiStore.setLoadingAction(payload);
    },
    closePopupModal() {
      dispatch.uiStore.closePopupModalAction();
    },
    showPopupModal(payload) {
      dispatch.uiStore.openPopupModalAction(payload);
    },
  }),
};
