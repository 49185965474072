import Warp from 'warp-sdk-js';

/**
 * Actions/Action Creators
 */
import { loginFailed, logoutSuccess } from './action-creator';
import { apiRequestStart, apiRequestEnd } from '../ui';
import { retrieveUser } from '../user';

import { EMAIL_AND_PASSWORD_INVALID } from '../../constants/error-messages';

// TODO: Try to change the session token stored in the localstorage and then handle the error.
export function login({ email, password }) {
    return dispatch => {
        const trigger = 'login';
        dispatch(apiRequestStart(trigger));

        return Warp.User.logIn(email, password)
        .then(() => {
            dispatch(retrieveUser());
            dispatch(apiRequestEnd(trigger));
        })
        .catch(({ status }) => {
            dispatch(loginFailed({ status, message: 'Failed', result: EMAIL_AND_PASSWORD_INVALID }));
            dispatch(apiRequestEnd(trigger));
        });
    }
}

export function logout()  {
    return dispatch => {
        return Warp.User.logOut()
        .then(() => {
            localStorage.removeItem(`${process.env.REACT_APP_WARP_API_URL}:x-warp-session-token`);
            localStorage.removeItem(`${process.env.REACT_APP_WARP_API_URL}:x-warp-user-current`);
            Warp.User._currentUser = null;
            dispatch(logoutSuccess());
        })
        .catch(() => dispatch(logoutSuccess()))
    }
}