import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  ErrorCode,
  ErrorPageWrapper,
  ErrorTitle,
  TextWrapper,
} from '../../components/Error/Error.component';

const styles = theme => ({
  errorWrapper: {
    backgroundColor: theme.palette.primary.main,
  },
});

const ErrorPage = ({ history, classes }) => {
  return (
    <ErrorPageWrapper className={classes.errorWrapper}>
      <TextWrapper>
        <ErrorCode>404</ErrorCode>
        <ErrorTitle>Page not found :(</ErrorTitle>
        <Button
          style={{ marginTop: 50 }}
          variant="contained"
          onClick={() => history.push('/')}
        >
          <KeyboardBackspaceIcon />
          &nbsp; &nbsp; Go Back
        </Button>
      </TextWrapper>
    </ErrorPageWrapper>
  );
};

export default connect()(withStyles(styles, { withTheme: true })(ErrorPage));
