window.config = {
  REACT_APP_CONNECT_API_URL: process.env.REACT_APP_CONNECT_API_URL,
  DASHBOARD_TYPE: process.env.REACT_APP_DASHBOARD_TYPE,
};

window.getProjectId = () => {
  switch (window.config.DASHBOARD_TYPE) {
    case 'mycare':
      return 3;
    case 'stayon':
      return 2;
    default:
      return 2;
  }
};

window.getPromotionId = () => {
  switch (window.config.DASHBOARD_TYPE) {
    case 'mycare':
      return 4;
    case 'stayon':
      return 1;
    default:
      return 1;
  }
};
