import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import './LoadingOverlay.style.scss';

class LoadingOverlay extends React.Component {
  render() {
  	const { palette } = this.props.theme;

    return (
      <div className="loading-container">
        <div className="loader" style={{ border: `8px solid ${palette.primary.main}` }} />
      </div>
    );
  }
}

export default withTheme()(LoadingOverlay);