import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

/**
 * Material UI - Core
 */
import {
  MenuList as MenuListCore,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@material-ui/core';

/**
 * Material UI - Icons
 */
import {
  Home as HomeIcon,
  LocalHospital as LocalHospitalIcon,
  LocalPharmacy as LocalPharmacyIcon,
  Face as FaceIcon,
  Category as CategoryIcon,
  ExitToApp as ExitToAppIcon,
  FileCopy as FileCopyIcon,
  ChevronRightTwoTone as ChevronRight,
} from '@material-ui/icons';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

/**
 * Actions
 */
import { logout } from '../../../actions/session';

/**
 * Styled Component
 */
const MenuList = styled(MenuListCore)`
  .is-active {
    background-color: #ebebeb;
    font-weight: bold;
  }
`;
const ListItemPopup = styled(ListItem)`
  :focus{
    background-color: white !important;
  }
  :hover, .is-active{
    background-color: #ebebeb !important;
  }
`;

const checkActive = (match, location) => {
  if ([
    '/rebate-summary',
    '/patient-compliance',
    '/refill-patient-outlet-transaction',
    '/patient-reenrollment'
  ].includes(location.pathname)) return true;
  else return false;
}

const checkActivePharmacies = (match, location) => {
  if ([
    '/pharmacies-summary',
    '/pharmacies-account',
    '/pharmacies-organization',
  ].includes(location.pathname)) return true;
  else return false;
}

// FIXME: Remove hidden comments once other pages are released

const Navigation = ({ dispatch }) => (
  <MenuList>
    <ListItem
      button
      component={NavLink}
      exact
      to="/"
      activeClassName="is-active"
    >
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
    <ListItem
      button
      component={NavLink}
      exact
      to="/products"
      activeClassName="is-active"
    >
      <ListItemIcon>
        <CategoryIcon />
      </ListItemIcon>
      <ListItemText primary="Products" />
    </ListItem>
    <ListItem
      button
      component={NavLink}
      exact
      to="/patients"
      activeClassName="is-active"
    >
      <ListItemIcon>
        <FaceIcon />
      </ListItemIcon>
      <ListItemText primary="Patients" />
    </ListItem>
    <PopupState variant="popover" popupId="popup-pharmacies">
      {popupState => (
        <React.Fragment>
          <ListItem
            button
            component={NavLink}
            to="#"
            isActive={checkActivePharmacies}
            activeClassName="is-active"
            {...bindTrigger(popupState)}
            aria-haspopup="true"
          >
            <ListItemIcon>
              <LocalPharmacyIcon />
            </ListItemIcon>
            <ListItemText primary="Pharmacies" />
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
          </ListItem>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ListItemPopup
              button
              component={NavLink}
              exact
              to="/pharmacies-summary"
              onClick={popupState.close}
            >
              <ListItemText primary="Summary" />
            </ListItemPopup>
            <ListItemPopup
              button
              component={NavLink}
              exact
              to="/pharmacies-account"
              onClick={popupState.close}
            >
              <ListItemText primary="Account View" />
            </ListItemPopup>
            <ListItemPopup
              button
              component={NavLink}
              exact
              to="/pharmacies-organization"
              onClick={popupState.close}
            >
              <ListItemText primary="Organization View" />
            </ListItemPopup>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
    <ListItem
      button
      component={NavLink}
      exact
      to="/physicians"
      activeClassName="is-active"
    >
      <ListItemIcon>
        <LocalHospitalIcon />
      </ListItemIcon>
      <ListItemText primary="Physicians" />
    </ListItem>
    <PopupState variant="popover" popupId="popup-reports">
      {popupState => (
        <React.Fragment>
          <ListItem
            button
            component={NavLink}
            to="#"
            isActive={checkActive}
            activeClassName="is-active"
            {...bindTrigger(popupState)}
            aria-haspopup="true"
          >
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
          </ListItem>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ListItemPopup
              button
              component={NavLink}
              exact
              to="/rebate-summary"
              onClick={popupState.close}
            >
              <ListItemText primary="Rebate Summary" />
            </ListItemPopup>
            <ListItemPopup
              button
              component={NavLink}
              exact
              to="/patient-compliance"
              onClick={popupState.close}
            >
              <ListItemText primary="Patient Compliance" />
            </ListItemPopup>
            <ListItemPopup
              button
              component={NavLink}
              exact
              to="/refill-patient-outlet-transaction"
              onClick={popupState.close}
            >
              <ListItemText primary="Refill Patient Outlet-Transaction" />
            </ListItemPopup>
            <ListItemPopup
              button
              component={NavLink}
              exact
              to="/patient-reenrollment"
              onClick={popupState.close}
            >
              <ListItemText primary="Patient Re-enrollment" />
            </ListItemPopup>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
    <ListItem
      button
      component={NavLink}
      to="/"
      onClick={() => dispatch(logout())}
    >
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </MenuList>
);

export default withRouter(connect()(Navigation));