// Classes
import HCP from '../../classes/hcp';
import Account from '../../classes/account';
import Organization from '../../classes/organization';

// Action Creators
import { 
    displayHCPerror, 
    displayPharmacySummary, displayPhysicianSummary, 
    displayAccountLeaderboard, displayOrganizationLeaderboard, 
    displayOrganizationSummary, 
    displayAccountSummary,
    displayMonthlyOrganizationTransactions,
    displayMonthlyAccountTransactions
} from './action-creators';
import { apiRequestEnd, apiRequestStart } from '../ui';
import Product from '../../classes/product';

export const getPharmacySummary = () => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        const userType = 'pharmacist';
        const trigger = 'pharmacySummary';
        
        dispatch(apiRequestStart(trigger));

        try {
           /**
            * Enrolled Entities
            */

            // Accounts
            const enrolledAccounts = await Account.getTotalEnrolled(filters);
            const totalEnrolledPharmacyAccounts = enrolledAccounts.find(hcp => hcp.type === 'pharmacist');

            // Organizations
            const enrolledOrganizations = await Organization.getTotalEnrolled(filters);
            const totalEnrolledPharmacyOrganizations = enrolledOrganizations.find(hcp => hcp.type === 'pharmacist');

            // HCPs
            const enrolledHCPs = await HCP.getTotalEnrolled(filters);
            const totalEnrolledPharmacists = enrolledHCPs.find(hcp => hcp.type === 'pharmacist');

            /**
            * Selling Entities
            */

            // Accounts
            const transactingAccounts = await Account.getTotalTransacting(filters);
            const totalTransactingPharmacyAccounts = transactingAccounts.find(hcp => hcp.type === 'pharmacist');

            // Organizations
            const transactingOrganizations = await Organization.getTotalTransacting(filters);
            const totalTransactingPharmacyOrganizations = transactingOrganizations.find(hcp => hcp.type === 'pharmacist');

            // HCP
            const transactingHCPs = await HCP.getTotalTransacting(filters);
            const totalTransactingPharmacists = transactingHCPs.find(hcp => hcp.type === 'pharmacist');

            /**
             * Transaction Data
             */
            const totalTransactions = await Product.getTotalTransactions(filters, userType);
            const totalTransactionBatches = totalTransactions.totalTransactionBatches;
            const totalTransactionCampaigns = totalTransactions.totalTransactionCampaigns;

            const totalQuantitySold = await Product.getTotalQuantitySold(filters, userType);

            dispatch(displayPharmacySummary({
                totalEnrolledPharmacyAccounts,
                totalTransactingPharmacyAccounts,

                totalEnrolledPharmacyOrganizations,
                totalTransactingPharmacyOrganizations,

                totalEnrolledPharmacists,
                totalTransactingPharmacists,

                totalTransactionBatches,
                totalTransactionCampaigns,
                totalQuantitySold
            }));
        }
        catch(e) {
            console.error(e);
            dispatch(displayHCPerror({
                status: e.status,
                message: e.message
            }));
        }

        dispatch(apiRequestEnd(trigger));
    };
};

export const getPhysicianSummary = () => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        const userType = 'physician';
        const trigger = 'physicianSummary';
        
        dispatch(apiRequestStart(trigger));

        try {
           /**
            * Enrolled Entities
            */

            // Accounts
            const enrolledAccounts = await Account.getTotalEnrolled(filters);
            const totalEnrolledPhysicianAccounts = enrolledAccounts.find(hcp => hcp.type === 'physician');

            // Organizations
            const enrolledOrganizations = await Organization.getTotalEnrolled(filters);
            const totalEnrolledPhysicianOrganizations = enrolledOrganizations.find(hcp => hcp.type === 'physician');

            // HCPs
            const enrolledHCPs = await HCP.getTotalEnrolled(filters);
            const totalEnrolledPhysicians = enrolledHCPs.find(hcp => hcp.type === 'physician');

            /**
            * Selling Entities
            */

            // Accounts
            const transactingAccounts = await Account.getTotalTransacting(filters);
            const totalTransactingPhysicianAccounts = transactingAccounts.find(hcp => hcp.type === 'physician');

            // Organizations
            const transactingOrganizations = await Organization.getTotalTransacting(filters);
            const totalTransactingPhysicianOrganizations = transactingOrganizations.find(hcp => hcp.type === 'physician');

            // HCP
            const transactingHCPs = await HCP.getTotalTransacting(filters);
            const totalTransactingPhysicians = transactingHCPs.find(hcp => hcp.type === 'physician');

            /**
             * Transaction Data
             */
            const totalTransactions = await Product.getTotalTransactions(filters, userType);
            const totalTransactionBatches = totalTransactions.totalTransactionBatches;
            const totalTransactionCampaigns = totalTransactions.totalTransactionCampaigns;

            const totalQuantitySold = await Product.getTotalQuantitySold(filters, userType);

            dispatch(displayPhysicianSummary({
                totalEnrolledPhysicianAccounts,
                totalTransactingPhysicianAccounts,

                totalEnrolledPhysicianOrganizations,
                totalTransactingPhysicianOrganizations,

                totalEnrolledPhysicians,
                totalTransactingPhysicians,

                totalTransactionBatches,
                totalTransactionCampaigns,
                totalQuantitySold
            }));
        }
        catch(e) {
            console.error(e);
            dispatch(displayHCPerror({
                status: e.status,
                message: e.message
            }));
        }

        dispatch(apiRequestEnd(trigger));
    };
};

export const getAccountLeaderboard = userType => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        const trigger = 'accountLeaderboard';
        
        dispatch(apiRequestStart(trigger));

        try {
            const leaderboard = await Account.getLeaderboard(filters, userType);
            
            dispatch(displayAccountLeaderboard({
                data: leaderboard,
                userType
            }));
        }
        catch (e) {
            console.error(e);
            dispatch(displayHCPerror({
                status: e.status,
                message: e.message
            }));
        }

        dispatch(apiRequestEnd(trigger));
    };
};

export const getOrganizationLeaderboard = userType => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        const trigger = 'organizationLeaderboard';
        
        dispatch(apiRequestStart(trigger));

        try {
            const leaderboard = await Organization.getLeaderboard(filters, userType);
            
            dispatch(displayOrganizationLeaderboard({
                data: leaderboard,
                userType
            }));
        }
        catch (e) {
            console.error(e);
            dispatch(displayHCPerror({
                status: e.status,
                message: e.message
            }));
        }

        dispatch(apiRequestEnd(trigger));
    };
};


export const getAccountSummary = userType => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        const trigger = 'accountsView';
        dispatch(apiRequestStart(trigger));

        try {
            const summary = await Account.getAccountSummary(filters, userType);
            
            dispatch(displayAccountSummary({
                data: summary,
                userType
            }));
        }
        catch (e) {
            console.error(e);
            dispatch(displayHCPerror({
                status: e.status,
                message: e.message
            }));
        }

        dispatch(apiRequestEnd(trigger));
    };
};

export const getOrganizationSummary = userType => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        const trigger = 'organizationsView';
        
        dispatch(apiRequestStart(trigger));

        try {
            const summary = await Organization.getOrganizationSummary(filters, userType);
            
            dispatch(displayOrganizationSummary({
                data: summary,
                userType
            }));
        }
        catch (e) {
            console.error(e);
            dispatch(displayHCPerror({
                status: e.status,
                message: e.message
            }));
        }

        dispatch(apiRequestEnd(trigger));
    };
};

export const getMonthlyTransactionsByOrganization = organizationBranch => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        const trigger = 'monthlyTransactions';
        
        dispatch(apiRequestStart(trigger));

        try {
            const transactions = await Organization.getMonthlyTransactions(filters, organizationBranch);
            dispatch(displayMonthlyOrganizationTransactions(transactions));
        }
        catch (e) {
            console.error(e);
            dispatch(displayHCPerror({
                status: e.status,
                message: e.message
            }));
        }

        dispatch(apiRequestEnd(trigger));
    };
};

export const getMonthlyTransactionsByAccount = accountNumber => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        const trigger = 'monthlyTransactions';
        
        dispatch(apiRequestStart(trigger));

        try {
            const transactions = await Account.getMonthlyTransactions(filters, accountNumber);
            dispatch(displayMonthlyAccountTransactions(transactions));
        }
        catch (e) {
            console.error(e);
            dispatch(displayHCPerror({
                status: e.status,
                message: e.message
            }));
        }

        dispatch(apiRequestEnd(trigger));
    };
};