import { RETRIEVE_USER_SUCCESS, RETRIEVE_USER_FAILED } from '../constants/action-types';

const defaultState = {};

const userReducer = (state = defaultState, action) => {
    switch(action.type) {
        case RETRIEVE_USER_SUCCESS:
            const { currentUser, organizationData, userPromotionData } = action.payload;

            return {
                id: currentUser.id,
                firstName: currentUser.get('first_name'),
                middleName: currentUser.get('middle_name'),
                lastName: currentUser.get('last_name'),
                email: currentUser.get('email'),
                mobile: currentUser.get('mobile'),
                type: currentUser.get('type'),
                organizationId: currentUser.get('organization').id,
                organizationBranch: organizationData ? organizationData.get('organization_branch') : null,
                locationId: currentUser.get('location') ? currentUser.get('location').id : null,
                userPromotionId: currentUser.get('user_promotion') ? currentUser.get('user_promotion').id : null,
                promotionCode: userPromotionData ? userPromotionData.get('promotion').get('code') : null
            }
        case RETRIEVE_USER_FAILED:
            return defaultState;
        default:
            return { ...state };
    }
}

export default userReducer;