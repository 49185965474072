import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ErrorCode, ErrorPageWrapper, ErrorTitle, TextWrapper } from '../ui/Error';

const ErrorPage = ({ history }) => {
    return (
        <ErrorPageWrapper>
            <TextWrapper>
                <ErrorCode>404</ErrorCode>
                <ErrorTitle>Page not found :(</ErrorTitle>
                <Button 
                    color='secondary'
                    style={{ marginTop: 50 }}
                    variant='contained'
                    onClick={() => history.push('/')}>
                    <KeyboardBackspaceIcon />
                    &nbsp;
                    &nbsp;
                    Go Back
                </Button>
            </TextWrapper>
        </ErrorPageWrapper>
    );
}

export default connect()(ErrorPage);