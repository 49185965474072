import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { rematchContext } from '../models/index.model';

/**
 * Pages
 */
import LoginPage from '../containers/LoginPage/LoginPage.container';
import MainPage from '../containers/MainPage/MainPage.container';
import LoadingOverlay from '../components/LoadingOverlay/LoadingOverlay.component';

class RequireAuthentication extends Component {
  render() {
    const { path, component } = this.props;

    return (
      <span>
        {this.props.uiStore.isLoading && <LoadingOverlay />}
        {this.props.userStore.user ? (
            <MainPage>
              <Route path={path} component={component} />
            </MainPage>
          ) : (
            <LoginPage />
          )
        }
      </span>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => {
  return {
    userStore: state.userStore,
    uiStore: state.uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  null,
  null,
  { context: rematchContext }
)(RequireAuthentication);
