import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../../common/models/index.model';
import queryString from 'query-string';
import * as formatters from '../../../common/utilities/formatters.utility';

// Components
import { DataCard } from '../../../common/components/Card/Card.component';
import PaginatedTable from '../../../common/components/PaginatedTable/PaginatedTable.component';

const { DASHBOARD_TYPE } = window.config;

class ApprovedPatientPage extends React.Component {
  state = {
    previousSearch: '',
    currentPage: 1,
    columns: [
      { Header: 'Patient ID', accessor: 'patient_id', clickable: true },
      { Header: 'HCP ID', accessor: 'hcp_id', show: (DASHBOARD_TYPE === 'mycare') },
      { Header: 'HCP Name', accessor: 'hcp_name', show: (DASHBOARD_TYPE === 'mycare') },
      { Header: 'Clinic Name', accessor: 'clinic_name', show: (DASHBOARD_TYPE === 'mycare') },
      { Header: 'HCP Email Address', accessor: 'hcp_email', show: (DASHBOARD_TYPE === 'mycare') },
      { Header: 'DKSH Number', accessor: 'dksh_number', show: (DASHBOARD_TYPE === 'mycare') },
      {
        Header: 'Product Bought',
        accessor: 'sku',
        show: (DASHBOARD_TYPE === 'mycare'),
      },
      {
        Header: 'Transaction Date',
        Cell: props => (
          <span>{formatters.date(props.original.transaction_date) || '--'}</span>
        ),
        show: (DASHBOARD_TYPE === 'mycare'),
      },
      {
        Header: 'Approval Date',
        Cell: props => (
          <span>{formatters.date(props.original.approval_date) || '--'}</span>
        ),
        show: (DASHBOARD_TYPE === 'mycare'),
      },
      { Header: 'Redemption Code', accessor: 'redemption_code' },
      { Header: 'Redemption Code Status', accessor: 'status' },
      {
        Header: 'Approval Date',
        Cell: props => (
          <span>{formatters.date(props.original.approval_date) || '--'}</span>
        ),
        show: (DASHBOARD_TYPE === 'stayon'),
      },
      {
        Header: 'Redemption Date',
        Cell: props => (
          <span>{formatters.date(props.original.redemption_date) || '--'}</span>
        ),
      },
    ],
  };

  async componentDidMount() {
    this.setState({ previousPath: this.props.location.pathname });
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date ||
      prevProps.filterStore.filters.doctor !== this.props.filterStore.filters.doctor
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;
    let params = queryString.parse(this.props.history.location.search) || {};
    await this.props.getApprovedPatientList({
      ...params,
      start_date,
      end_date,
    });
    if (
      params.page <= this.props.patientStore.approvedPatients.pages &&
      params.page > 0
    ) {
      this.setState({ currentPage: params.page || 1 });
    } else {
      this.props.history.push(
        `${pathname}?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`
      );
    }
  };

  _openExportModal = () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    this.props.showPopupModal({
      title: 'Approved Patients',
      message: 'Are you sure you want to export this table?',
      actions: [
        {
          label: 'Export',
          onClick: async () => {
            await this.props.exportApprovedPatientList({
              start_date,
              end_date,
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  render() {
    const { columns, currentPage } = this.state;
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;

    return (
      <DataCard
        title="Approved Patients for Free Box"
        style={{ background: '#8b1b3f', height: '100%' }}
        onExport={() => this._openExportModal()}
        dataCount={this.props.patientStore.approvedPatients.count}
      >
        <PaginatedTable
          data={this.props.patientStore.approvedPatients.list}
          columns={columns}
          totalPages={this.props.patientStore.approvedPatients.pages}
          currentPage={currentPage}
          history={this.props.history}
          clickAccessor='patient_id'
          onRowClick={patient_id =>
            this.props.history.push(
              `${pathname}/${patient_id}?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`
            )
          }
        />
      </DataCard>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ patientStore, filterStore, uiStore }) => {
  return {
    ...patientStore,
    ...filterStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(ApprovedPatientPage);
