// Classes
import Account from '../../classes/account';
import HCP from '../../classes/hcp';
import Organization from '../../classes/organization';
import Product from '../../classes/product';
import Patient from '../../classes/patient';

// Actions
import { displayProgramSummary } from './action-creators';
import { displayProductSummary } from '../product/action-creators';
import { displayPatientSummary } from '../patient/action-creators';
import { displayHCPSummary, displayPharmacySummary, displayPhysicianSummary } from '../hcp/action-creators';
import { apiRequestStart, apiRequestEnd } from '../ui';

export const getSummaries = () => {
    return async (dispatch, getState) => {
        const filters = getState().filter;
        
        dispatch(apiRequestStart('programSummary'));
        dispatch(apiRequestStart('pharmacySummary'));
        dispatch(apiRequestStart('physicianSummary'));

        try {
           /**
            * Enrolled Entities
            */

            // Accounts
            const enrolledAccounts = await Account.getTotalEnrolled(filters);
            const totalEnrolledAccounts = enrolledAccounts.find(hcp => hcp.type === 'total');
            const totalEnrolledPharmacyAccounts = enrolledAccounts.find(hcp => hcp.type === 'pharmacist');
            const totalEnrolledPhysicianAccounts = enrolledAccounts.find(hcp => hcp.type === 'physician');

            // Organizations
            const enrolledOrganizations = await Organization.getTotalEnrolled(filters);
            const totalEnrolledOrganizations = enrolledOrganizations.find(hcp => hcp.type === 'total');
            const totalEnrolledPharmacyOrganizations = enrolledOrganizations.find(hcp => hcp.type === 'pharmacist');
            const totalEnrolledPhysicianOrganizations = enrolledOrganizations.find(hcp => hcp.type === 'physician');

            // HCPs
            const enrolledHCPs = await HCP.getTotalEnrolled(filters);
            const totalEnrolledHCPs = enrolledHCPs.find(hcp => hcp.type === 'total');
            const totalEnrolledPharmacists = enrolledHCPs.find(hcp => hcp.type === 'pharmacist');
            const totalEnrolledPhysicians = enrolledHCPs.find(hcp => hcp.type === 'physician');

            /**
            * Selling Entities
            */

            // Accounts
            const transactingAccounts = await Account.getTotalTransacting(filters);
            const totalTransactingAccounts = transactingAccounts.find(hcp => hcp.type === 'total');
            const totalTransactingPharmacyAccounts = transactingAccounts.find(hcp => hcp.type === 'pharmacist');
            const totalTransactingPhysicianAccounts = transactingAccounts.find(hcp => hcp.type === 'physician');

            // Organizations
            const transactingOrganizations = await Organization.getTotalTransacting(filters);
            const totalTransactingOrganizations = transactingOrganizations.find(hcp => hcp.type === 'total');
            const totalTransactingPharmacyOrganizations = transactingOrganizations.find(hcp => hcp.type === 'pharmacist');
            const totalTransactingPhysicianOrganizations = transactingOrganizations.find(hcp => hcp.type === 'physician');

            // HCPs
            const transactingHCPs = await HCP.getTotalTransacting(filters);
            const totalTransactingHCPs = transactingHCPs.find(hcp => hcp.type === 'total');
            const totalTransactingPharmacists = transactingHCPs.find(hcp => hcp.type === 'pharmacist');
            const totalTransactingPhysicians = transactingHCPs.find(hcp => hcp.type === 'physician');

            /**
             * Product Data
             */
            const totalQuantitySold = await Product.getTotalQuantitySold(filters);
            const totalTransactions = await Product.getTotalTransactions(filters);

            /**
             * Patients
             */
            const totalTransactingPatients = await Patient.getTotalTransacting(filters);

            dispatch(displayProgramSummary({
                totalEnrolledAccounts,
                totalEnrolledOrganizations,
                
                totalTransactingAccounts,
                totalTransactingOrganizations
            }));

            dispatch(displayHCPSummary({
                totalEnrolledHCPs,
                totalTransactingHCPs
            }));

            dispatch(displayPharmacySummary({
                totalEnrolledPharmacyAccounts,
                totalTransactingPharmacyAccounts,

                totalEnrolledPharmacyOrganizations,
                totalTransactingPharmacyOrganizations,

                totalEnrolledPharmacists,
                totalTransactingPharmacists
            }));

            dispatch(displayPhysicianSummary({
                totalEnrolledPhysicianAccounts,
                totalTransactingPhysicianAccounts,

                totalEnrolledPhysicianOrganizations,
                totalTransactingPhysicianOrganizations,

                totalEnrolledPhysicians,
                totalTransactingPhysicians
            }));

            dispatch(displayProductSummary({
                totalQuantitySold,
                totalTransactions
            }));

            dispatch(displayPatientSummary({ totalTransactingPatients }));
        }
        catch(e) {
            console.error(e);
        }

        dispatch(apiRequestEnd('programSummary'));
        dispatch(apiRequestEnd('pharmacySummary'));
        dispatch(apiRequestEnd('physicianSummary'));
    };
};