import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "../constants/action-types";

const defaultState = {
    isLoading: false,
    isLoggedIn: false
}

const sessionReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export default sessionReducer;