import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

const dashboard_style = {
  title: 'mClinica Dashboard',
  font: 'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
  color: '#ffffff',
  favicon: 'favicon.ico',
  style: `body { font-family: Roboto, sans-serif; }`,
};

switch (window.config.DASHBOARD_TYPE) {
  case 'stayon':
    dashboard_style.title = 'StayOn | Dashboard';
    dashboard_style.font =
      'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700';
    dashboard_style.color = '#ee7421';
    dashboard_style.favicon = 'icon_Svv_icon.ico';
    dashboard_style.style = `
    body {
      font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif;
    }`;
    break;
  case 'mycare':
    dashboard_style.title = 'MYCare | Dashboard';
    dashboard_style.font =
      'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700';
    dashboard_style.color = '#0476bd';
    dashboard_style.favicon = 'MYCare_logo.ico';
    dashboard_style.style = ` body {
      font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif;
    }`;
    break;
  case 'mthp':
    dashboard_style.title = 'MyTotalHealth | Dashboard';
    dashboard_style.font =
      'https://fonts.googleapis.com/css?family=Roboto:300,400,700';
    dashboard_style.color = '#00539B';
    dashboard_style.favicon = 'mth-favicon.ico';
    dashboard_style.style = ` body {
      font-family: 'Roboto', -apple-system, BlinkMacSystemFont,
        'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif;
    }`;
    break;
  default:
    break;
}

const stayon = createMuiTheme({
  palette: {
    primary: {
      main: '#8b1b3f',
    },
    secondary: {
      main: '#F2662B',
    },
    error: {
      main: '#E53935',
    },
    text: {
      primary: '#555',
    },
  },
  typography: {
    fontSize: 12.5,
    lineHeight: 1.5,
    fontFamily: ['Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
});

const mycare = createMuiTheme({
  palette: {
    primary: {
      main: '#0476bd',
    },
    secondary: {
      main: '#0476bd',
    },
    error: {
      main: '#E53935',
    },
    text: {
      primary: '#555',
    },
  },
  typography: {
    fontSize: 12.5,
    lineHeight: 1.5,
    fontFamily: ['Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
});

const mthp = createMuiTheme({
  palette: {
    primary: {
      main: '#00539B',
    },
    secondary: {
      main: '#F2662B',
    },
    error: {
      main: '#E53935',
    },
    text: {
      primary: '#555',
    },
  },
  typography: {
    fontSize: 13,
    lineHeight: 1.5,
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

class Header extends React.Component {
  render() {
    return (
      <Helmet defer={false}>
        <link rel="shortcut icon" href={dashboard_style.favicon} />
        <meta name="theme-color" content={dashboard_style.color} />
        <link href={dashboard_style.font} rel="stylesheet" />
        <style>{dashboard_style.style}</style>
        <title>{dashboard_style.title}</title>
      </Helmet>
    );
  }
}

export { stayon, mycare, mthp, Header };
