import store from 'store';
import axios from 'axios';
import { serialize } from './utilities.service.js';
import stores from '../models/index.model';

class ApiClient {
  async renewRefreshToken() {
    try {
      const result = await axios.post(
        `${window.config.REACT_APP_CONNECT_API_URL}/core/authenticate/token`,
        serialize({
          grant_type: 'refresh_token',
          scope: '*',
          client_id: 'default',
          refresh_token: (store.get('user') || {}).refreshToken,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-connect-project-id': window.getProjectId(),
            'x-connect-promotion-id': window.getPromotionId(),
            'x-client-type': 'web',
          },
        }
      );
      store.set('user', result.data);
    } catch (e) {
      console.error(`Error refreshing auth token ${e.message}`);
      store.remove('user');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  async get(path, qs) {
    stores.dispatch.uiStore.showLoading(true);
    const generatePayload = () => {
      return {
        method: 'get',
        url: `${window.config.REACT_APP_CONNECT_API_URL}${path}`,
        params: qs,
        headers: {
          Authorization: `Bearer ${(store.get('user') || {}).accessToken}`,
          'x-connect-project-id': window.getProjectId(),
          'x-connect-promotion-id': window.getPromotionId(),
          'x-client-type': 'web',
        },
        responseType: 'json',
      };
    };

    let response = null;
    try {
      console.debug('---REQUEST.URL---');
      console.debug(path);
      console.debug('---REQUEST.BODY---');
      console.debug(JSON.stringify(qs, null, 2));
      response = await axios(generatePayload());
    } catch (e) {
      try {
        if (e.response && e.response.status === 401) {
          await this.renewRefreshToken();
          response = await axios(generatePayload());
        } else {
          stores.dispatch.uiStore.showLoading(false);
          throw e;
        }
      } catch (ee) {
        throw ee;
      }
    }
    stores.dispatch.uiStore.showLoading(false);
    return response;
  }

  async download(path, qs, filename) {
    stores.dispatch.uiStore.showLoading(true);
    const generatePayload = () => {
      return {
        method: 'get',
        url: `${window.config.REACT_APP_CONNECT_API_URL}${path}`,
        params: qs,
        headers: {
          Authorization: `Bearer ${(store.get('user') || {}).accessToken}`,
          'x-connect-project-id': window.getProjectId(),
          'x-connect-promotion-id': window.getPromotionId(),
          'x-client-type': 'web',
        },
        responseType: 'blob',
      };
    };

    let response = null;
    try {
      console.debug('---REQUEST.URL---');
      console.debug(path);
      console.debug('---REQUEST.BODY---');
      console.debug(JSON.stringify(qs, null, 2));
      response = await axios(generatePayload());
    } catch (e) {
      try {
        if (e.response && e.response.status === 401) {
          await this.renewRefreshToken();
          response = await axios(generatePayload());
        } else {
          stores.dispatch.uiStore.showLoading(false);
          throw e;
        }
      } catch (ee) {
        throw ee;
      }
    }
    stores.dispatch.uiStore.showLoading(false);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename ? filename : 'file.csv');
    document.body.appendChild(link);
    link.click();
  }

  async post(path, body, options = {}) {
    stores.dispatch.uiStore.showLoading(true);
    const generatePayload = () => {
      return {
        method: 'post',
        url: `${window.config.REACT_APP_CONNECT_API_URL}${path}`,
        data: body,
        headers: {
          Authorization: `Bearer ${(store.get('user') || {}).accessToken}`,
          'x-connect-project-id': window.getProjectId(),
          'x-connect-promotion-id': window.getPromotionId(),
          'x-client-type': 'web',
        },
        responseType: 'json',
        ...options,
      };
    };

    let response = null;
    try {
      console.debug('---REQUEST.URL---');
      console.debug(path);
      console.debug('---REQUEST.BODY---');
      console.debug(JSON.stringify(body, null, 2));

      response = await axios(generatePayload());
    } catch (e) {
      try {
        if (e.response && e.response.status === 401) {
          response = await axios(generatePayload());
        } else {
          stores.dispatch.uiStore.showLoading(false);
          throw e;
        }
      } catch (ee) {
        stores.dispatch.uiStore.showLoading(false);
        throw ee;
      }
    }
    stores.dispatch.uiStore.showLoading(false);
    return response;
  }

  async getFileURL(path, qs) {
    stores.dispatch.uiStore.showLoading(true);
    const generatePayload = () => {
      return {
        method: 'get',
        url: `${window.config.REACT_APP_CONNECT_API_URL}${path}`,
        params: qs,
        headers: {
          Authorization: `Bearer ${(store.get('user') || {}).accessToken}`,
          'x-connect-project-id': window.getProjectId(),
          'x-connect-promotion-id': window.getPromotionId(),
          'x-client-type': 'web',
        },
        responseType: 'arraybuffer',
      };
    };

    let response = null;
    try {
      console.debug('---REQUEST.URL---');
      console.debug(path);
      console.debug('---REQUEST.BODY---');
      console.debug(JSON.stringify(qs, null, 2));
      response = await axios(generatePayload());
    } catch (e) {
      try {
        if (e.response && e.response.status === 401) {
          await this.renewRefreshToken();
          response = await axios(generatePayload());
        } else {
          stores.dispatch.uiStore.showLoading(false);
          throw e;
        }
      } catch (ee) {
        throw ee;
      }
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    stores.dispatch.uiStore.showLoading(false);
    return url;
  }
}

export default new ApiClient();