import { ADD_FILTERS, CLEAR_FILTERS, GET_FILTERS, GET_PRODUCTS } from '../../constants/action-types';

export const addFilters = (data) => ({
    type: ADD_FILTERS,
    payload: data
});

export const resetFilters = () => ({
    type: CLEAR_FILTERS
});

export const retrieveFilters = ({ brands, accounts, organizations }) => ({
    type: GET_FILTERS,
    payload: {
        brands,
        accounts,
        organizations
    }
});

export const retrieveProducts = products => ({
    type: GET_PRODUCTS,
    payload: products
});