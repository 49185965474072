import Request from './request';

const route = 'reports';

export default class Report {
    static downloadRebateSummaryReport = async (filters) => {
        try {
            const filename = `Pfizer_Malaysia-Patient_Rebate_Summary_Report-${filters.dateFrom}_to_${filters.dateTo}.xlsx`;
            return await Request.download(`/${route}/rebate-summary-report`, filters, {}, filename);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static downloadPatientComplianceReport = async (filters, params) => {
        try {
            const filename = `Pfizer_Malaysia-Patient_Compliance_Report-${filters.dateFrom}_to_${filters.dateTo}.xlsx`;
            return await Request.download(`/${route}/patient-compliance-report`, {
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo
            }, params, filename);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static downloadRefillPatientOutletTransactionReport = async (filters, params) => {
        try {
            const filename = `Pfizer_Malaysia-Refill_Patient_Outlet_Transaction_Report-${filters.dateFrom}_to_${filters.dateTo}.xlsx`;
            return await Request.download(`/${route}/refill-patient-outlet-transaction-report`, {
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo
            }, params, filename);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static downloadPatientReenrollmentReport = async (filters, params) => {
        try {
            const filename = `Pfizer_Malaysia-Patient_Re-enrollment_Report-${filters.dateFrom}_to_${filters.dateTo}.xlsx`;
            return await Request.download(`/${route}/patient-reenrollment-report`, {
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo
            }, params, filename);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
}