//Components
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { rematchContext } from '../../models/index.model';

//SCSS
import './PopupAlert.style.scss';

const popupRoot = document.getElementById('popup');
/**
 * [Component of the Popup Alert Modal.]
 * @props string            {title}     [req]  [Title of the alert that will be displayed on top of the modal.]
 * @props boolean           {open}      [req]  [Toggle Key of the alert modal.]
 * @props function          {onClose}   [req]  [Function to invoke on modal close.]
 * @props string            {message}   [req]  [content of the alert]
 * @props array(objects)    {actions}   [x]    [Array of actions to be placed in the modal]
 *      @attribute string       {label}     [req]  [Label to be shown on the button]
 *      @attribute function     {onClick}   [req]  [Function to call on action click]
 */
class PopupAlertComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.el = document.createElement('div');
  }

  componentDidMount() {
    popupRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    popupRoot.appendChild(this.el);
  }

  render() {
    const { uiStore } = this.props;
    return ReactDOM.createPortal(
      <Dialog
        open={uiStore.popupAlert.open}
        onClose={this.props.closePopupModal}
      >
        <div>
          <DialogTitle>{uiStore.popupAlert.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{uiStore.popupAlert.message}</DialogContentText>
          </DialogContent>
          {uiStore.popupAlert.actions &&
          !uiStore.popupAlert.disableCloseButton ? (
            <DialogActions>
              <Button
                color="primary"
                key={`popup-action-button-cancel`}
                onClick={this.props.closePopupModal}
              >
                Close
              </Button>
              {uiStore.popupAlert.actions.map((action, actionIndex) => (
                <Button
                  color="secondary"
                  key={`popup-action-button-${actionIndex}`}
                  onClick={action.onClick}
                >
                  {action.label}
                </Button>
              ))}
            </DialogActions>
          ) : null}
        </div>
      </Dialog>,
      this.el
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ uiStore }) => {
  return {
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(PopupAlertComponent);
