import { init } from '@rematch/core';
import React from 'react';

const store = init({
  models: {
    userStore: require('./user.model').default,
    patientStore: require('./patient.model').default,
    summaryStore: require('./summary.model').default,
    transactionStore: require('./transaction.model').default,
    hcpStore: require('./hcp.model').default,
    filterStore: require('./filter.model').default,
    uiStore: require('./ui.model').default,
  },
  redux: {
    reducers: {}
  }
});

export default store;
export const rematchContext = React.createContext();
