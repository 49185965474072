import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
// import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
// import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const snackBarContentStyle = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    }
});

const snackBarStyle = theme => ({
    margin: {
        margin: theme.spacing.unit,
        maxWidth: 'none', 
    }
});

function CustomSnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={`${classes[variant]} ${className}`}            
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={`${classes.icon} ${classes.iconVariant}`} />
                    {message}
                </span>
            }
            /* action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]} */
            {...other}
        />
    );
}

const SnackBarContent = withStyles(snackBarContentStyle)(CustomSnackbarContent);

class CustomSnackbar extends React.Component {
    render() {
        const {
            classes,
            anchorOrigin: { vertical, horizontal },
            isOpen = false,
            variant,
            message
        } = this.props;

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical,
                        horizontal,
                    }}
                    open={isOpen}>
                    <SnackBarContent
                        className={classes.margin}
                        variant={variant}
                        message={message}
                    />
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(snackBarStyle)(CustomSnackbar);