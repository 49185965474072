import store from 'store';
import { serialize } from '../services/utilities.service.js';
import apiClient from '../services/apiClient.service.js';

export default {
  state: {
    user: store.get('user') || null,
  },
  reducers: {
    setUser(state, payload) {
      return { ...state, user: payload };
    },
  },
  effects: dispatch => ({
    async loginUser({ username, password }, rootState) {
      try {
        const result = await apiClient.post(
          `/core/authenticate/token`,
          serialize({
            username,
            password,
            grant_type: 'password',
            client_id: 'default',
            scope: '*',
          })
        );

        if (!result) {
          throw new Error('Failed to login');
        }

        if (result.data.user.type !== 'dashboard') {
          store.remove('user');
          dispatch.userStore.setUser(null);
          throw new Error('You are not authorized to access this system.');
        }

        store.set('user', result.data);
        dispatch.userStore.setUser(result.data);
      } catch (e) {
        throw (e.response && e.response.data) || e;
      }
    },

    async logoutUser(payload, rootState) {
      try {
        await apiClient.post(`/core/authenticate/logout`);
        store.remove('user');
        dispatch.userStore.setUser(null);
      } catch (e) {
        throw e;
      }
    },
  }),
};
