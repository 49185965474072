import React from 'react';
import { connect } from 'react-redux';

import { Grid, TableRow, TableCell, Tooltip, Button } from '@material-ui/core';
import {  DataCard } from '../ui/Card';
import Dialog from '../ui/Dialog';
import Table from '../ui/Table';
import { BarStack, ColumnGraph } from '../ui/Chart';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Action Creators
import { 
    getOrganizationLeaderboard, 
    getOrganizationSummary,
    getMonthlyTransactionsByOrganization
} from '../../actions/hcp';

const userType = 'pharmacist';

class PharmacyPage extends React.Component {
    state = {
        organizationDialog: false,
        organizationBranch: null
    }

     componentDidMount() {
        this.getOrganizationLeaderboard();
        this.getOrganizationSummary();
    }

    getOrganizationLeaderboard = () => {
        const { dispatch } = this.props;
        dispatch(getOrganizationLeaderboard(userType));
    }

    getOrganizationSummary = () => {
        const { dispatch } = this.props;
        dispatch(getOrganizationSummary(userType));
    }

    getOrganizationColumns = () => [
        'Organization',
        'Account',
        'Total Boxes Sold',
        'Total Transacting Patients',
        'Total Transactions',
        'Action'
    ]

    getOrganizationTransactions = organizationBranch => {
        const { dispatch } = this.props;
        dispatch(getMonthlyTransactionsByOrganization(organizationBranch));

        this.setState({  
            organizationDialog: true,
            organizationBranch
        });
    }

    displayTransactions = () => {
        const monthlyTransactions = this.props.hcp.monthlyTransactions;

        return <div>
            <ColumnGraph
                xAxis='Period'
                yAxis='Total Transactions'
                height={350}
                series={monthlyTransactions} />
        </div>;
    }

    close = dialogType => {
        this.setState({ 
            [dialogType]: false, 
            organizationBranch: null 
        });
    }
    
    render() {
        const {  organizationDialog, organizationBranch } = this.state;
        const { ui, hcp } = this.props;
        const {
            pharmacyOrganizationLeaderboards,
            pharmacyOrganizations
        } = hcp.pharmacy;

        return <Grid spacing={16} container>

            <Grid item xs={12}>
                <DataCard
                    title='Organization Leaderboard'
                    subtitle='Top Selling Pharmacy Outlets'>

                    <BarStack
                        xAxis='Branches / Outlets'
                        yAxis='Boxes Sold'
                        height={350}
                        isLoading={ui.organizationLeaderboard.isLoading}
                        series={pharmacyOrganizationLeaderboards} />
                </DataCard>
            </Grid>
            


            <Dialog
                open={organizationDialog}
                content={this.displayTransactions()}
                title={`Monthly Transactions by ${organizationBranch}`}>
                <Button 
                    onClick={() => this.close('organizationDialog')} 
                    color='primary' 
                    variant='contained'>
                    Close
                </Button>
            </Dialog>
            
            <Grid item xs={12}>
                <DataCard
                    title='Pharmacy Organizations View'
                    subtitle='Table summary of each pharmacy organization'
                    isLoading={ui.organizationsView.isLoading}>

                    <Table
                        columns={this.getOrganizationColumns()}
                        totalCount={pharmacyOrganizations.length}
                        rows={pharmacyOrganizations.map((row, index) => (
                            <TableRow key={index} hover>
                                <TableCell>{row.organization_branch}</TableCell>
                                <TableCell>{row.account_name}</TableCell>
                                <TableCell numeric>{row.total_quantity_sold}</TableCell>
                                <TableCell numeric>{row.total_transacting_patients}</TableCell>
                                <TableCell numeric>{row.total_transactions}</TableCell>
                                <TableCell>
                                    <Tooltip title='View monthly transactions'>
                                        <Button 
                                            color='secondary' 
                                            variant='contained' 
                                            onClick={() => this.getOrganizationTransactions(row.organization_branch)}>
                                            <VisibilityIcon />
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))} />
                </DataCard>
            </Grid>
        </Grid>;
    }
};

const mapStateToProps = state => ({
    filter: state.filter,
    ui: state.ui,
    hcp: state.hcp
});

export default connect(mapStateToProps)(PharmacyPage);