import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Typography } from '@material-ui/core';

const { DASHBOARD_TYPE } = window.config;
export const Chart = ({ options, title, subtitle, type, height = 250 }) => (
  <div>
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type,
          height,
        },
        title: {
          text: title,
        },
        subtitle: {
          text: subtitle,
        },
        ...options,
      }}
    />
  </div>
);

export const LineGraph = ({
  title = '',
  subtitle = '',
  enableDataLabels = true,
  series,
  height,
  legendLayout = 'horizontal',
  yAxis = 'Values',
  xAxis = '',
}) => {
  if (!series || (series && series.data && series.data.length === 0))
    return <Typography>No data found</Typography>;
  else
    return (
      <Chart
        title={title}
        subtitle={subtitle}
        type="line"
        height={height}
        options={{
          xAxis: {
            title: { text: xAxis },
            categories:
              series && series.data
                ? series.data.map(datum => datum.name)
                : [],
          },
          yAxis: {
            title: { text: yAxis },
          },
          plotOptions: {
            line: {
              dataLabels: {
                enabled: enableDataLabels,
              },
              color: DASHBOARD_TYPE === 'stayon' ? '#F2662B' : '#60A4D4',
              marker: {
                fillColor:
                  DASHBOARD_TYPE === 'stayon' ? '#8b1b3f' : '#0475BD',
              },
            },
          },
          legend: {
            layout: legendLayout,
          },
          series,
        }}
      />
    );
};
