import React from 'react';
import {
    DateRangeOutlined as DateRangeOutlinedIcon
} from '@material-ui/icons';
import { Grid, withStyles } from '@material-ui/core';

export const filterStyles = theme => ({
    card: {
        backgroundColor: theme.palette.common.white,
    },
    cardHeader: {
        color: theme.palette.secondary.main,
        fontWeight: 900,
        textTransform: 'uppercase',
        fontSize: theme.typography.title.fontSize
    },
    sectionHeader: {
        color: theme.palette.grey[600],
        textTransform: 'uppercase',
        fontSize: theme.typography.body2.fontSize,
        marginBottom: 10
    },
    section: {
        marginBottom: 12
    },
    dateBox: {
        backgroundColor: theme.palette.grey[100],
        padding: 10
    },
    dateIcon: {
        color: theme.palette.grey[700],
        fontSize: theme.typography.headline.fontSize
    },
    label: {
        color: theme.palette.grey[400],
        fontSize: '0.625rem'
    },
    value: {
        color: theme.palette.grey[700],
        fontSize: theme.typography.caption.fontSize
    },
    chip: {
        fontSize: theme.typography.caption.fontSize,
        marginRight: 7,
        marginBottom: 3
    }
});

export const DateRange = withStyles(filterStyles)(({ label, value, classes }) => {
    return <Grid 
            container 
            direction='row' 
            justify='center'
            alignItems='center' 
            className={classes.dateBox}>
        <Grid item xs={2}>
            <DateRangeOutlinedIcon className={classes.dateIcon} />
        </Grid>
        <Grid item xs={10}>
            <div className={classes.label}>{label}</div>
            <div className={classes.value}>{value}</div>
        </Grid>
    </Grid>;
});