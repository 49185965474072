import { LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_UNAUTHORIZED } from '../constants/action-types';

const defaultState = {
    login: {}
}

const apiResponseReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload
            }
        case LOGIN_FAILED:
            return {
                ...state,
                login: action.payload
            }
        case LOGIN_UNAUTHORIZED:
            return {
                ...state,
                login: action.payload
            }
        default:
            return state;
    }
}

export default apiResponseReducer;