import React, { Component } from 'react';
import { connect } from 'react-redux';
import { detect } from 'detect-browser';
import isEmail from 'validator/lib/isEmail';

/**
 * Material UI - Core
 */
import {
  Grid,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  IconButton,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';

/**
 * Material UI - Icons
 */
import { Visibility, VisibilityOff } from '@material-ui/icons';

/**
 * Custom Components
 */
import SnackBar from '../ui/Snackbar';

/**
 * Constants
 */
import {
  EMAIL_REQUIRED,
  EMAIL_INVALID_FORMAT,
  PASSWORD_REQUIRED,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
} from '../../constants/error-messages';

/**
 * Actions
 */
import { login } from '../../actions/session';

const MTHPLogo =
  'https://s3-ap-southeast-1.amazonaws.com/connect-my-pfizer-production/assets/images/mthp-logo-blue.png';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: {
        email: '',
        password: '',
      },
      isSnackBarOpen: false,
      snackBarMessage: '',
    };
  }

  componentDidMount() {
    const { name /*, version, os */ } = detect();
    if (name === 'ie')
      this.setState({
        isSnackBarOpen: true,
        snackBarMessage: `Your browser is not supported. To use MyTotalHealth, we recommend using the latest version of Chrome, Firefox, or Safari.`,
      });
  }

  onInputChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value.trim() });

  handleClickShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  onSubmit = async e => {
    e.preventDefault();
    const { dispatch, onSubmit } = this.props;
    const { email, password } = this.state;
    const error = { ...this.state.error };

    error['email'] = '';
    error['password'] = '';

    if (email.length === 0)
        error['email'] = EMAIL_REQUIRED;
    else if (!isEmail(email))
        error['email'] = EMAIL_INVALID_FORMAT;
    else if (password.length === 0)
        error['password'] = PASSWORD_REQUIRED;
    else if (password.length < 8)
        error['password'] = PASSWORD_MIN_LENGTH;
    else if (password.length > 16)
        error['password'] = PASSWORD_MAX_LENGTH;
    else
        dispatch(login({ email, password })).then(() => onSubmit());

    this.setState({ error });
  };

  render() {
    const { isLoading, apiResponse } = this.props;
    const {
      email,
      password,
      error,
      showPassword,
      isSnackBarOpen,
      snackBarMessage,
    } = this.state;

    return (
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        justify={'center'}
        style={{ background: '#00539b', height: '100%' }}
      >
        <Grid item md={3} sm={6} xs={9}>
          <Card>
            <div style={{ padding: '50px 50px 0 50px' }}>
              <img
                src={MTHPLogo}
                alt="MyTotalHealth Logo"
                style={{ width: '100%' }}
              />
            </div>
            <CardContent>
              <form onSubmit={this.onSubmit}>
                <FormControl
                  error={Boolean(error.email)}
                  fullWidth={true}
                  margin="normal"
                >
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Input
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.onInputChange}
                    autoFocus
                  />
                  <FormHelperText>{error.email}</FormHelperText>
                </FormControl>

                <FormControl
                  error={Boolean(error.password)}
                  fullWidth={true}
                  margin="normal"
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    onChange={this.onInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={this.handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>{error.password}</FormHelperText>
                </FormControl>

                <Typography
                  color="error"
                  style={{ marginTop: '10px', textAlign: 'center' }}
                >
                  {apiResponse && apiResponse.result}
                </Typography>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                  style={{ marginTop: 12 }}
                >
                  <Button
                    color="primary"
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                  >
                    {isLoading === true && (
                      <CircularProgress size={16} thickness={5} />
                    )}
                    Login
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <SnackBar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          isOpen={isSnackBarOpen}
          variant="warning"
          message={snackBarMessage}
        />
      </Grid>
    );
  }
}

// REDUX
const mapStateToProps = state => ({
  isLoading: state.ui && state.ui.login.isLoading,
  apiResponse: state.apiResponse && state.apiResponse.login,
});

export default connect(
  mapStateToProps
)(LoginForm);
