import apiClient from '../services/apiClient.service.js';
import stores from '../models/index.model';
import moment from 'moment-timezone';

const summaryPath = '/dashboard/summary';
const dateFormat = 'YYYY-MM-DD';

export default {
  state: {
    summary: {
      total_enrolled_hcps: 0,
      total_transacting_hcps: 0,
      total_enrolled_patients: 0,
      total_transacting_patients: 0,
      total_eligible_patients: 0,
      total_potentially_eligible_patients: 0,
      total_boxes_sold: 0,
      total_boxes_redeemed: 0,
    },
  },
  reducers: {
    setSummary(state, payload) {
      return { ...state, summary: payload };
    },
  },
  effects: dispatch => ({
    async getSummary(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(summaryPath, {
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.summaryStore.setSummary(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Summary: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },
  }),
};
