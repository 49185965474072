import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { rematchContext } from '../../models/index.model';
import queryString from 'query-string';
import { Grid, Button } from '@material-ui/core';
import 'react-table/react-table.css';
import './PaginatedTable.style.scss';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { withTheme } from '@material-ui/core/styles';

class ReactSimpleTable extends React.Component {
  state = {
    columns: []
  }

  componentDidMount() {
    let { columns } = this.props;
    let propsColumns = columns;
    // SET universal minWidth and maxWidth for columns
    propsColumns.forEach(column => {
      if (!column.width) {
        if (column.Header && column.Header.includes('Email')) {
          column.minWidth = 260;
        }
        else if (column.Header && (
          column.Header.includes('Meditag') ||
          column.Header.includes('Hologram') ||
          column.Header.includes('Name') ||
          column.Header.includes('Physician')
        )) {
          column.minWidth = 220;
        }
        else {
          column.Header && ((column.Header.length * 8) > 150 ? column.minWidth = column.Header.length * 8 : column.minWidth = 150)
          
        }
      }
    });
    this.setState({ columns: propsColumns });
  }

  pagination = () => {
    let params = queryString.parse(this.props.history.location.search);
    let currentPage = this.props.currentPage || params.page;
    if (
      currentPage < 1 ||
      currentPage > this.props.totalPages ||
      isNaN(currentPage)
    )
      currentPage = 1;

    return (
      <Grid container spacing={24}>
        <Grid item xs={4} container justify="flex-end">
          <Grid item xs={6}>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              onClick={this._onPrevious}
              disabled={(currentPage || 1) <= 1 ? true : false}
            >
              <KeyboardArrowLeft style={{ marginRight: 6 }} />
              Previous
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <div>
            Page {currentPage || 1} of {this.props.totalPages}
          </div>
        </Grid>
        <Grid item xs={4} container justify="flex-start">
          <Grid item xs={6}>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              onClick={this._onNext}
              disabled={
                (currentPage || 1) >= this.props.totalPages ? true : false
              }
            >
              Next
              <KeyboardArrowRight style={{ marginLeft: 6 }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  _onNext = async () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { pageIdentifier } = this.props;
    let params = queryString.parse(this.props.history.location.search);
    params.start_date && delete params.start_date;
    params.end_date && delete params.end_date;
    let currentPage = this.props.currentPage || params[pageIdentifier || 'page'];
    pageIdentifier && delete params[pageIdentifier];
    params.page && delete params.page;
    const stringParams = queryString.stringify(params);
    if (
      currentPage < 1 ||
      currentPage > this.props.totalPages ||
      isNaN(currentPage)
    )
      currentPage = 1;
    let page = parseInt(currentPage || 1, 10) + 1;
    this.props.history.push(
      `${
        this.props.history.location.pathname
      }?${stringParams}&${pageIdentifier || 'page'}=${page}&start_date=${start_date}&end_date=${end_date}`
    );
  };

  _onPrevious = async () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { pageIdentifier } = this.props;
    let params = queryString.parse(this.props.history.location.search);
    params.start_date && delete params.start_date;
    params.end_date && delete params.end_date;
    let currentPage = this.props.currentPage || params[pageIdentifier || 'page'];
    pageIdentifier && delete params[pageIdentifier];
    params.page && delete params.page;
    const stringParams = queryString.stringify(params);
    if (
      currentPage < 1 ||
      currentPage > this.props.totalPages ||
      isNaN(currentPage)
    )
      currentPage = 1;
    let page = parseInt(currentPage || 1, 10) - 1;
    this.props.history.push(
      `${
        this.props.history.location.pathname
      }?${stringParams}&${pageIdentifier || 'page'}=${page}&start_date=${start_date}&end_date=${end_date}`
    );
  };

  render() {
    let { data, onRowClick, clickAccessor } = this.props;
    let { columns } = this.state;
    const { palette } = this.props.theme;

    return (
      <ReactTable
        data={Array.isArray(data) ? data : []}
        columns={columns}
        filterable={false}
        sortable={false}
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]).match(new RegExp(filter.value, 'i'))
        }
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                opacity: rowInfo.original.deleted_at ? '0.4' : '1',
                background: rowInfo.original.deleted_at ? 'gray' : 'white',
              },
            };
          } else {
            return {};
          }
        }}
        getTdProps={(state, rowInfo, column) => {
          return {
            onClick: e => {
              if (
                onRowClick &&
                rowInfo != null &&
                column.Header !== 'Actions' &&
                column.clickable &&
                clickAccessor
              ) {
                onRowClick(rowInfo.original[clickAccessor]);
              }
            },
            style: column.clickable &&
              column.accessor && {
                color: palette.primary.main,
                cursor: 'pointer',
                fontWeight: 'bold',
              },
          };
        }}
        getTheadProps={() => {
          return {
            style: {
              backgroundColor: palette.primary.main,
            },
          };
        }}
        defaultPageSize={10}
        showPageSizeOptions={false}
        PaginationComponent={this.pagination}
        className="-highlight card"
      />
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ filterStore }) => {
  return {
    ...filterStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(withTheme()(ReactSimpleTable));
