import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../../common/models/index.model';
import queryString from 'query-string';
// import { ResponsiveCalendar } from '@nivo/calendar';
// import { ResponsiveBar } from '@nivo/bar';

// Components
import { Grid } from '@material-ui/core';
import {
  PersonTwoTone as PatientIcon,
  LocalHospitalTwoTone as DoctorIcon,
  InboxTwoTone as BoxIcon,
  //ViewModuleTwoTone as CalendarIcon,
  //ViewColumnTwoTone as BarIcon,
} from '@material-ui/icons';
import { SummaryCard } from '../../../../components/ui/Card';
import AggregatedValue from '../../../common/components/AggregatedValue/AggregatedValue.component';

const { DASHBOARD_TYPE } = window.config;

class SummaryPage extends React.Component {
  state = {
    previousSearch: '',
  };

  async componentDidMount() {
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    else if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    let params = queryString.parse(this.props.history.location.search) || {};
    await this.props.getSummary({
      ...params
    });
  };

  render() {
    const { start_date, end_date } = this.props.filterStore.filters;
    const {
      total_enrolled_hcps,
      total_transacting_hcps,
      total_enrolled_patients,
      total_transacting_patients,
      total_eligible_patients,
      total_potentially_eligible_patients,
      total_boxes_sold,
      total_boxes_redeemed,
    } = this.props.summaryStore.summary;

    return (
      <Grid spacing={8} container>
        <Grid item xs={8}>
          <SummaryCard
            icon={<PatientIcon fontSize="large" style={{ marginRight: 6 }} />}
            title="Patients"
          >
            <AggregatedValue
              value={total_enrolled_patients}
              description="Number of Enrolled Patients"
              link={`/enrolled-patients?page=1&start_date=${start_date}&end_date=${end_date}`}
              md={6}
            />
            <AggregatedValue
              value={total_transacting_patients}
              description="Number of Transacting Patients"
              link={`/transacting-patients?page=1&start_date=${start_date}&end_date=${end_date}`}
              md={6}
            />
            <AggregatedValue
              value={total_eligible_patients}
              description="Number of Eligible Patients"
              link={`/eligible-patients?page=1&start_date=${start_date}&end_date=${end_date}`}
              md={6}
            />
            <AggregatedValue
              value={total_potentially_eligible_patients}
              description="Number of Potentially Eligible Patients"
              link={`/potentially-eligible-patients?page=1&start_date=${start_date}&end_date=${end_date}`}
              md={6}
            />
          </SummaryCard>
        </Grid>
        <Grid item xs={8}>
          <SummaryCard
            icon={<DoctorIcon fontSize="large" style={{ marginRight: 6 }} />}
            title={DASHBOARD_TYPE === 'stayon'? 'Doctors' : 'HCPs'}
          >
            <AggregatedValue
              value={total_enrolled_hcps}
              description={`Number of ${DASHBOARD_TYPE === 'stayon'? 'Doctors' : 'HCP'} Enrolled`}
              link={`/enrolled-${DASHBOARD_TYPE === 'stayon'? 'doctors' : 'hcps'}?page=1&start_date=${start_date}&end_date=${end_date}`}
              md={6}
            />
            <AggregatedValue
              value={total_transacting_hcps}
              description={`Number of Transacting ${DASHBOARD_TYPE === 'stayon'? 'Doctors' : 'HCP'}`}
              link={`/transacting-${DASHBOARD_TYPE === 'stayon'? 'doctors' : 'hcps'}?page=1&start_date=${start_date}&end_date=${end_date}`}
              md={6}
            />
          </SummaryCard>
        </Grid>
        <Grid item xs={8}>
          <SummaryCard
            icon={<BoxIcon fontSize="large" style={{ marginRight: 6 }} />}
            title="Boxes"
          >
            <AggregatedValue
              value={total_boxes_sold}
              description="Total Boxes Sold"
              link={`/purchased-products?page=1&start_date=${start_date}&end_date=${end_date}`}
              md={6}
            />
            <AggregatedValue
              value={total_boxes_redeemed}
              description="Total Boxes Redeemed"
              link={`/redeemed-products?page=1&start_date=${start_date}&end_date=${end_date}`}
              md={6}
            />
          </SummaryCard>
        </Grid>
        {/*<Grid item xs={6} lg={8} xl={6} container spacing={16}>
          <Grid item xs={12}>
            <GraphCard
              icon={<BarIcon fontSize="large" style={{ marginRight: 6 }} />}
              title="Summary"
            >
              <ResponsiveBar
                data={[
                  {
                    country: 'AD',
                    'hot dog': 51,
                    'hot dogColor': 'hsl(291, 70%, 50%)',
                    burger: 95,
                    burgerColor: 'hsl(88, 70%, 50%)',
                    sandwich: 13,
                    sandwichColor: 'hsl(5, 70%, 50%)',
                    kebab: 109,
                    kebabColor: 'hsl(229, 70%, 50%)',
                    fries: 59,
                    friesColor: 'hsl(155, 70%, 50%)',
                    donut: 122,
                    donutColor: 'hsl(217, 70%, 50%)',
                  },
                  {
                    country: 'AE',
                    'hot dog': 6,
                    'hot dogColor': 'hsl(235, 70%, 50%)',
                    burger: 24,
                    burgerColor: 'hsl(331, 70%, 50%)',
                    sandwich: 185,
                    sandwichColor: 'hsl(352, 70%, 50%)',
                    kebab: 54,
                    kebabColor: 'hsl(175, 70%, 50%)',
                    fries: 51,
                    friesColor: 'hsl(29, 70%, 50%)',
                    donut: 191,
                    donutColor: 'hsl(350, 70%, 50%)',
                  },
                  {
                    country: 'AF',
                    'hot dog': 164,
                    'hot dogColor': 'hsl(225, 70%, 50%)',
                    burger: 96,
                    burgerColor: 'hsl(255, 70%, 50%)',
                    sandwich: 152,
                    sandwichColor: 'hsl(107, 70%, 50%)',
                    kebab: 110,
                    kebabColor: 'hsl(59, 70%, 50%)',
                    fries: 5,
                    friesColor: 'hsl(294, 70%, 50%)',
                    donut: 160,
                    donutColor: 'hsl(343, 70%, 50%)',
                  },
                  {
                    country: 'AG',
                    'hot dog': 137,
                    'hot dogColor': 'hsl(69, 70%, 50%)',
                    burger: 19,
                    burgerColor: 'hsl(209, 70%, 50%)',
                    sandwich: 1,
                    sandwichColor: 'hsl(190, 70%, 50%)',
                    kebab: 3,
                    kebabColor: 'hsl(234, 70%, 50%)',
                    fries: 187,
                    friesColor: 'hsl(61, 70%, 50%)',
                    donut: 43,
                    donutColor: 'hsl(39, 70%, 50%)',
                  },
                ]}
                keys={[
                  'hot dog',
                  'burger',
                  'sandwich',
                  'kebab',
                  'fries',
                  'donut',
                ]}
                indexBy="country"
                margin={{
                  top: 50,
                  right: 130,
                  bottom: 50,
                  left: 60,
                }}
                padding={0.3}
                colors="nivo"
                colorBy="id"
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: 'fries',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'sandwich',
                    },
                    id: 'lines',
                  },
                ]}
                borderColor="inherit:darker(1.6)"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'country',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'food',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="inherit:darker(1.6)"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </GraphCard>
          </Grid>
          <Grid item xs={12}>
            <GraphCard
              icon={
                <CalendarIcon fontSize="large" style={{ marginRight: 6 }} />
              }
              title="Patients and Boxes"
            >
              <ResponsiveCalendar
                data={[
                  {
                    day: '2015-10-01',
                    value: 385,
                  },
                  {
                    day: '2015-11-02',
                    value: 255,
                  },
                  {
                    day: '2015-08-26',
                    value: 28,
                  },
                ]}
                from="2015-03-01"
                to="2015-07-12"
                emptyColor="#eeeeee"
                colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
                margin={{
                  top: 100,
                  right: 30,
                  bottom: 60,
                  left: 30,
                }}
                yearSpacing={40}
                monthBorderColor="#ffffff"
                monthLegendOffset={10}
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                legends={[
                  {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 34,
                    itemHeight: 36,
                    itemDirection: 'top-to-bottom',
                  },
                ]}
              />
            </GraphCard>
          </Grid>
        </Grid>*/}
      </Grid>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ summaryStore, filterStore }) => {
  return {
    ...summaryStore,
    ...filterStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(SummaryPage);
