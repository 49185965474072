import Request from './request';

const route = 'patients';
export default class Patient {
    static getTotalEnrolled = async (filters) => {
        try {
            const response = await Request.get(`/${route}/total-enrolled-patients`, filters);
            return response.data.total_enrolled_patients;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
    
    static getTotalTransacting = async (filters) => {
        try {
            const response = await Request.get(`/${route}/total-transacting-patients`, filters);
            return response.data.total_transacting_patients;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
    
    // This function was previously called total refillers in our previous version
    static getTotalVisiting = async (filters) => {
        try {
            const response = await Request.get(`/${route}/total-visiting-patients`, filters);
            return response.data.total_visiting_patients;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getAveragePatientVisits = async (filters) => {
        try {
            const response = await Request.get(`/${route}/average-visits`, filters);
            const averageVisits = response.data.average_visits ? parseFloat(response.data.average_visits) : 0;
            return Math.floor(averageVisits);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getMonthlyEnrolledGraph = async (filters) => {
        try {
            const response = await Request.get(`/${route}/enrolled-patients-timegraph`, filters);
            return response.data.monthly;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
    
    static getMonthlyTransactingGraph = async (filters) => {
        try {
            const response = await Request.get(`/${route}/transacting-patients-timegraph`, filters);
            return response.data.monthly;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getMonthlyTransactionRefillersAndPatients = async (filters) => {
        try {
            const response = await Request.get(`/${route}/refiller-new-patients`, filters);
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
}