import Request from './request';

const route = 'accounts';

export default class Account {
    static getTotalEnrolled = async filters => {
        try {
            const response = await Request.get(`/${route}/total-enrolled-accounts`, filters);
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
    
    static getTotalTransacting = async filters => {
        try {
            const response = await Request.get(`/${route}/total-transacting-accounts`, filters);
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getLeaderboard = async (filters, userType) => {
        try {
            const response = await Request.get(`/${route}/leaderboard`, filters, { user_type: userType });
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getAccountSummary = async (filters, userType) => {
        try {
            const response = await Request.get(`/${route}/account-summary`, filters, { user_type: userType });
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getMonthlyTransactions = async (filters, accountNumber) => {
        try {
            const response = await Request.get(`/${route}/monthly-transactions-by-account`, filters, { 
                account_number: accountNumber 
            });
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
};