import React from 'react';
import packageJson from '../../../../../package.json';
import {
  AppBar,
  Toolbar,
  Drawer,
  Divider,
  withStyles,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import StayOnNavigation from '../../../stayon/components/Navigation/Navigation.component';
import MyCareNavigation from '../../../mycare/components/Navigation/Navigation.component';
import Filter from '../../components/Filter/Filter.component';
import { connect } from 'react-redux';
import { rematchContext } from '../../models/index.model';
// import ActiveFiltersCard from '../ui/ActiveFilters';

/**
 * Material UI - Icons
 */
import { ExitToAppTwoTone as ExitToApp } from '@material-ui/icons';

const StayOnLogo = '../stayOn-Logo-white.png';
const MyCareLogo = '../MyCare_Horizontal Logo.png';

const { DASHBOARD_TYPE } = window.config;
var logo = null;
var logoWidth = null;

switch (DASHBOARD_TYPE) {
  case 'stayon':
    logo = StayOnLogo;
    logoWidth = 70;
    break;
  case 'mycare':
    logo = MyCareLogo;
    logoWidth = 150;
    break;
  default:
    break;
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100%',
    position: 'relative',
    zIndex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
  },
  drawerPaper: {
    position: 'relative',
    width: 210,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    minWidth: 0, // So the Typography noWrap works
    padding: theme.spacing.unit * 1,
  },
  toolbar: theme.mixins.toolbar,
  version: {
    fontSize: '0.8em',
    margin: '5px 0 0 15px',
    color: 'transparent',
  },
});

const MainPage = ({ classes, children }) => (
  <div className={classes.root}>
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={6} container alignItems="flex-end">
            <img src={logo} alt={` ${DASHBOARD_TYPE} logo`} width={logoWidth} />
            {packageJson.version && (
              <span className={classes.version}>v. {packageJson.version}</span>
            )}
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Logout />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
    <Drawer
      anchor="left"
      variant="permanent"
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.toolbar} />
      <Divider />
      {DASHBOARD_TYPE === 'stayon' && <StayOnNavigation />}
      {DASHBOARD_TYPE === 'mycare' && <MyCareNavigation />}
    </Drawer>
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container spacing={8}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          {children}
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Filter />
        </Grid>
      </Grid>
    </main>
  </div>
);

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToProps = ({ userStore }) => {
  return {
    ...userStore,
  };
};

const Logout = connect(
  mapStateToPropsRematch,
  mapDispatchToProps,
  null,
  { context: rematchContext }
)(
  class Logout extends React.Component {
    render() {
      return (
        <Button
          color="primary"
          onClick={async () => await this.props.logoutUser()}
        >
          <ExitToApp style={{ marginRight: 8, fill: '#fff' }} />
          <Typography style={{ color: '#fff' }}>Logout</Typography>
        </Button>
      );
    }
  }
);

export default withStyles(styles, { withTheme: true })(MainPage);
