import React, { Component } from 'react';
import { connect } from 'react-redux';
import { detect } from 'detect-browser';
import isEmail from 'validator/lib/isEmail';
import { rematchContext } from '../../models/index.model';

/**
 * Material UI - Core
 */
import {
  Grid,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  IconButton,
  FormHelperText,
  Input,
  InputLabel,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

/**
 * Material UI - Icons
 */
import { Visibility, VisibilityOff } from '@material-ui/icons';

/**
 * Custom Components
 */
import SnackBar from '../../components/SnackBar/SnackBar.component';

/**
 * Constants
 */
import {
  EMAIL_REQUIRED,
  EMAIL_INVALID_FORMAT,
  PASSWORD_REQUIRED,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
} from '../../../../constants/error-messages';

/**
 * SCSS
 */
import './LoginPage.container.scss';

const StayOnLogo = '../stayOn-Logo.png';
const MyCareLogo = '../MyCare_Logo_Rounded.png';
const { DASHBOARD_TYPE } = window.config;
var logo = null;
switch (DASHBOARD_TYPE) {
  case 'stayon':
    logo = StayOnLogo;
    break;
  case 'mycare':
    logo = MyCareLogo;
    break;
  default:
    break;
}

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: {
        email: '',
        password: '',
      },
      isSnackBarOpen: false,
      snackBarMessage: '',
    };
  }

  componentDidMount() {
    const { name /*, version, os */ } = detect();
    if (name === 'ie')
      this.setState({
        isSnackBarOpen: true,
        snackBarMessage: `Your browser is not supported. To use ${window.config.DASHBOARD_TYPE === 'stayon' ? 'StayOn' : 'MYCare'}, we recommend using the latest version of Chrome, Firefox,
         or Safari.`,
      });
    if (this.props.userStore.user) {
      this.props.history.push('/summary');
    }
  }

  onInputChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value.trim() });

  handleClickShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  onSubmit = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    const error = { ...this.state.error };

    error['email'] = '';
    error['password'] = '';

    if (email.length === 0) error['email'] = EMAIL_REQUIRED;
    else if (!isEmail(email)) error['email'] = EMAIL_INVALID_FORMAT;
    else if (password.length === 0) error['password'] = PASSWORD_REQUIRED;
    else if (password.length < 8) error['password'] = PASSWORD_MIN_LENGTH;
    else if (password.length > 25) error['password'] = PASSWORD_MAX_LENGTH;
    else {
      try {
        await this.props.loginUser({ username: email, password });
      } catch (e) {
        this.setState({
          snackBarMessage: e.message,
          isSnackBarOpen: true,
        });
      }
    }
    this.setState({ error });
  };

  render() {
    const { isLoading } = this.props.uiStore;
    const {
      email,
      password,
      error,
      showPassword,
      isSnackBarOpen,
      snackBarMessage,
    } = this.state;
    const { palette } = this.props.theme;

    return (
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        justify={'center'}
        style={{ background: palette.primary.main, height: '100%' }}
      >
        <Grid item md={3} sm={6} xs={9}>
          <Card>
            <div style={{ padding: '50px 50px 0 50px' }}>
              <img
                src={logo}
                alt={` ${DASHBOARD_TYPE} logo`}
                style={{ width: '100%' }}
              />
            </div>
            <CardContent>
              <form onSubmit={this.onSubmit}>
                <FormControl
                  error={Boolean(error.email)}
                  fullWidth={true}
                  margin="normal"
                >
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Input
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.onInputChange}
                    inputProps={{
                      spellCheck: false,
                    }}
                    autoFocus
                  />
                  <FormHelperText>{error.email}</FormHelperText>
                </FormControl>

                <FormControl
                  error={Boolean(error.password)}
                  fullWidth={true}
                  margin="normal"
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    onChange={this.onInputChange}
                    inputProps={{ minLength: 8, maxLength: 25 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={this.handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>{error.password}</FormHelperText>
                </FormControl>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                  style={{ marginTop: 12 }}
                >
                  <Button
                    color="secondary"
                    //disabled={isLoading}
                    type="submit"
                    variant="contained"
                  >
                    {isLoading && (
                      <CircularProgress
                        color="secondary"
                        size={16}
                        thickness={5}
                        style={{ marginRight: 8 }}
                      />
                    )}
                    Login
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <SnackBar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          isOpen={isSnackBarOpen}
          variant="warning"
          message={snackBarMessage}
        />
      </Grid>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ userStore, uiStore }) => {
  return {
    ...userStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(withTheme()(LoginPage));
