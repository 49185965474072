import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../../common/models/index.model';
import queryString from 'query-string';
import * as formatters from '../../../common/utilities/formatters.utility';

// Components
import { Grid, Button } from '@material-ui/core';
import { DataCard } from '../../../common/components/Card/Card.component';
import PaginatedTable from '../../../common/components/PaginatedTable/PaginatedTable.component';

// Material UI - Icons
import { Check, Close } from '@material-ui/icons';

class EligiblePatientPage extends React.Component {
  state = {
    previousSearch: '',
    currentPage: 1,
    columns: [
      { Header: 'Patient ID', accessor: 'patient_number', clickable: true },
      {
        Header: 'Purchase Date of 4th box',
        Cell: props => <span>{formatters.date(props.original.date)}</span>,
      },
      {
        Header: 'Action',
        Cell: props => (
          <Grid container justify="center">
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() =>
                this._openApproveModal(
                  props.original.campaign_product_promotion_id
                )
              }
              disabled={props.original.status !== 'pending'}
              style={{ marginRight: 6 }}
            >
              <Check fontSize="small" style={{ marginRight: 6 }} />
              Approve
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() =>
                this._openDisapproveModal(
                  props.original.campaign_product_promotion_id
                )
              }
              disabled={props.original.status !== 'pending'}
            >
              <Close fontSize="small" style={{ marginRight: 6 }} />
              Disapprove
            </Button>
          </Grid>
        ),
        width: 250,
      },
    ],
  };

  _openApproveModal = campaign_product_promotion_id => {
    let params = queryString.parse(this.props.history.location.search) || {};
    this.props.showPopupModal({
      title: 'Eligible Patients',
      message: 'Are you sure you want to approve this patient for a free box?',
      actions: [
        {
          label: 'Approve',
          onClick: async () => {
            await this.props.postApprovePatient({
              campaign_product_promotion_id,
              ...params,
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  _openDisapproveModal = campaign_product_promotion_id => {
    let params = queryString.parse(this.props.history.location.search) || {};
    this.props.showPopupModal({
      title: 'Eligible Patients',
      message: 'Are you sure you want to disapprove this patient for a free box?',
      actions: [
        {
          label: 'Disapprove',
          onClick: async () => {
            await this.props.postDisapprovePatient({
              campaign_product_promotion_id,
              ...params,
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  async componentDidMount() {
    this.setState({ previousPath: this.props.location.pathname });
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date || 
      prevProps.filterStore.filters.doctor !== this.props.filterStore.filters.doctor
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;
    let params = queryString.parse(this.props.history.location.search) || {};
    await this.props.getPatientList({
      ...params,
      eligible: true,
      start_date,
      end_date,
    });
    if (
      params.page <= this.props.patientStore.eligiblePatients.pages &&
      params.page > 0
    ) {
      this.setState({ currentPage: params.page || 1 });
    } else {
      this.props.history.push(`${pathname}?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`);
    }
  };

  _openExportModal = () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    this.props.showPopupModal({
      title: 'Eligible Patients',
      message: 'Are you sure you want to export this table?',
      actions: [
        {
          label: 'Export',
          onClick: async () => {
            await this.props.exportPatientList({
              start_date,
              end_date,
              eligible: true,
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  render() {
    const { columns, currentPage } = this.state;
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;

    return (
      <DataCard
        title="Eligible Patients"
        style={{ background: '#8b1b3f', height: '100%' }}
        onExport={() => this._openExportModal()}
        dataCount={this.props.patientStore.eligiblePatients.count}
      >
        <PaginatedTable
          data={this.props.patientStore.eligiblePatients.list}
          columns={columns}
          totalPages={this.props.patientStore.eligiblePatients.pages}
          currentPage={currentPage}
          history={this.props.history}
          clickAccessor='patient_number'
          onRowClick={patient_number =>
            this.props.history.push(`${pathname}/${patient_number}?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`)
          }
        />
      </DataCard>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({ patientStore, filterStore, uiStore }) => {
  return {
    ...patientStore,
    ...filterStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(EligiblePatientPage);
