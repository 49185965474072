// Classes
import Report from '../../classes/report';

// Action Creators
import { displayReportError } from './action-creators';
import { apiRequestStart, apiRequestEnd } from '../ui';

export const downloadRebateSummaryReport = () => {
    return async (dispatch, getState) => {
        const trigger = 'rebateSummary';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const response = await Report.downloadRebateSummaryReport(filters);
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }
    };
};

export const downloadPatientComplianceReport = ({ brand_id }) => {
    return async (dispatch, getState) => {
        const trigger = 'patientCompliance';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const response = await Report.downloadPatientComplianceReport(filters, { brand_id });
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }        
    };
};

export const downloadRefillPatientOutletTransactionReport = ({ brand_id }) => {
    return async (dispatch, getState) => {
        const trigger = 'refillPatientOutletTransaction';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const response = await Report.downloadRefillPatientOutletTransactionReport(filters, { brand_id });
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }        
    };
};

export const downloadPatientReenrollmentReport = () => {
    return async (dispatch, getState) => {
        const trigger = 'patientReenrollment';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const response = await Report.downloadPatientReenrollmentReport(filters);
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }
    };
};