import apiClient from '../services/apiClient.service.js';
import stores from '../models/index.model';
import moment from 'moment-timezone';

const patientPath = '/dashboard/patients';
const dateFormat = 'YYYY-MM-DD';

export default {
  state: {
    eligiblePatients: {
      list: [],
      count: 0,
      pages: 1,
    },
    potentiallyEligiblePatients: {
      list: [],
      count: 0,
      pages: 1,
    },
    approvedPatients: {
      list: [],
      count: 0,
      pages: 1,
    },
    enrolledPatients: {
      list: [],
      count: 0,
      pages: 1,
    },
    transactingPatients: {
      list: [],
      count: 0,
      pages: 1,
    },
    patientDetail: {
      id: 0,
      unique_code: '09999999999',
      is_subscribed: null,
      is_verified: null,
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: new Date(),
    },
    patientTransactions: {
      list: [],
      count: 0,
      pages: 1,
    },
    dailyTotalEnrolledPatients: {
      name: '',
      data: [],
    }
  },
  reducers: {
    setEligiblePatients(state, payload) {
      return { ...state, eligiblePatients: payload };
    },
    setPotentiallyEligiblePatients(state, payload) {
      return { ...state, potentiallyEligiblePatients: payload };
    },
    setApprovedPatients(state, payload) {
      return { ...state, approvedPatients: payload };
    },
    setEnrolledPatients(state, payload) {
      return { ...state, enrolledPatients: payload };
    },
    setTransactingPatients(state, payload) {
      return { ...state, transactingPatients: payload };
    },
    setPatientDetail(state, payload) {
      return { ...state, patientDetail: payload };
    },
    setPatientTransactionList(state, payload) {
      return { ...state, patientTransactions: payload };
    },
    setDailyTotalEnrolledPatients(state, payload) {
      return { ...state, dailyTotalEnrolledPatients: payload };
    },
  },
  effects: dispatch => ({
    async getPatientList(
      {
        page = 1,
        eligible = false,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(patientPath, {
          page,
          eligible,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        if (eligible) {
          dispatch.patientStore.setEligiblePatients(result.data.result);
        } else {
          dispatch.patientStore.setPotentiallyEligiblePatients(
            result.data.result
          );
        }
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Patients: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getApprovedPatientList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(`${patientPath}/approved`, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.patientStore.setApprovedPatients(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Patients: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getEnrolledPatientList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(`${patientPath}/enrolled`, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.patientStore.setEnrolledPatients(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Enrolled Patients: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getTransactingPatientList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(`${patientPath}/transacting`, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.patientStore.setTransactingPatients(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Transacting Patients: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getPatientDetail({ patient_id }, rootState) {
      try {
        const result = await apiClient.get(`${patientPath}/${patient_id}`);

        dispatch.patientStore.setPatientDetail({
          ...result.data.result
        });
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Patients: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getPatientTransactionList(
      {
        page = 1,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = null,
        patient_id
      }
      , rootState
    ) {
      try {
        const result = await apiClient.get(`${patientPath}/${patient_id}/transactions`, {
          page,
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
          format,
        });

        dispatch.patientStore.setPatientTransactionList(result.data.result);
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Patient Transaction History: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async postApprovePatient(
      {
        campaign_product_promotion_id = '',
        page = 1,
        eligible = true,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        await apiClient.post(
          `${patientPath}/${campaign_product_promotion_id}/approve`
        );
        await dispatch.patientStore.getPatientList({
          page,
          eligible,
          start_date,
          end_date
        });
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Patients: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async postDisapprovePatient(
      {
        campaign_product_promotion_id = '',
        page = 1,
        eligible = true,
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        await apiClient.post(
          `${patientPath}/${campaign_product_promotion_id}/disapprove`
        );
        await dispatch.patientStore.getPatientList({
          page,
          eligible,
          start_date,
          end_date
        });
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Patients: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async getDailyTotalEnrolledPatientList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
      },
      rootState
    ) {
      try {
        const result = await apiClient.get(`${patientPath}/enrolled/daily`, {
          start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
          end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
        });

        dispatch.patientStore.setDailyTotalEnrolledPatients({
          name: 'Total Patients',
          data: result.data.result.list && result.data.result.list.map(dayItem => ({
              name: dayItem.day,
              y: parseInt(dayItem.total_patients, 10)
          }))
        });
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Enrolled Patients: Error',
          message: (e.response && e.response.data) ? e.response.data.message : e.message,
          actions: [],
        });
      }
    },

    async exportPatientList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
        eligible = false,
      },
      rootState
    ) {
      try {
        let filename = `eligible-patients-${(new Date()).toJSON()}.csv`;
        if (!eligible) {
          filename = `potentially-${filename}`;
        }

        const result = await apiClient.download(patientPath, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
            eligible,
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Patients: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

    async exportPatientTransactionList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
        eligible = false,
        patient_id
      },
      rootState
    ) {
      try {
        let filename = `patient-${patient_id}-${(new Date()).toJSON()}.csv`;

        const result = await apiClient.download(`${patientPath}/${patient_id}/transactions`, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
            eligible,
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Patient Transaction History: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

    async exportApprovedPatientList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
      },
      rootState
    ) {
      try {
        let filename = `approved-patients-${(new Date()).toJSON()}.csv`;

        const result = await apiClient.download(`${patientPath}/approved`, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Approved Patients: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

    async exportEnrolledPatientList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
      },
      rootState
    ) {
      try {
        let filename = `enrolled-patients-${(new Date()).toJSON()}.csv`;

        const result = await apiClient.download(`${patientPath}/enrolled`, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Enrolled Patients: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

    async exportTransactingPatientList(
      {
        start_date = moment().subtract(2, 'months').startOf('day').format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        format = 'csv',
      },
      rootState
    ) {
      try {
        let filename = `transacting-patients-${(new Date()).toJSON()}.csv`;

        const result = await apiClient.download(`${patientPath}/transacting`, {
            start_date: moment(start_date).utc().add(1, 'days').startOf('day').toJSON(),
            end_date: moment(end_date).utc().add(1, 'days').endOf('day').toJSON(),
            format,
          },
          filename,
        );

        return result;
      } catch (e) {
        console.error(e.response ? e.response : e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Transacting Patients: Error',
          message: (e.response && e.response.statusText) ? e.response.statusText : e.message,
          actions: [],
        });
      }
    },

  }),
};
