import Request from './request';

const route = 'users';

export default class HCP {
    static getTotalTransacting = async (filters) => {
        try {
            const response = await Request.get(`/${route}/total-transacting-hcps`, filters);
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getTotalEnrolled = async (filters) => {
        try {
            const response = await Request.get(`/${route}/total-enrolled-hcps`, filters);
            return response.data;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
};