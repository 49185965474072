import {
    DISPLAY_REPORT_PAGE_ERROR
} from '../../constants/action-types';

export const displayReportError = ({ status, message }) => ({
    type: DISPLAY_REPORT_PAGE_ERROR,
    payload: {
        status,
        message
    }
});