import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #8b1b3f;
`;

export const TextWrapper = styled.div`
    margin: 0 auto;
    text-align: left;
    width: 70%;
    padding-top: 15%;
    padding-right: 40px;

    @media (max-width: 768px) {
        width: 90%;
        padding-top: 40%;
    }
`;

export const ErrorCode = styled.div`
    font-size: 7em;
    color: #FFF;
    font-weight: 900;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const ErrorTitle = styled.div`
    font-size: 5.5em;
    color: #FFF;
    text-transform: capitalize;

    @media (max-width: 768px) {
        width: 100%;
        font-size: 4.5em;
    }
`;