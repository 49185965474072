import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../models/index.model';
import queryString from 'query-string';
import * as formatters from '../../utilities/formatters.utility';

// Components
import { Button } from '@material-ui/core';
import { DataCard } from '../../components/Card/Card.component';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable.component';

const { DASHBOARD_TYPE } = window.config;

class PurchasedProductPage extends React.Component {
  state = {
    previousSearch: '',
    currentPage: 1,
    meditag: '',
    columns: [
      {
        Header: 'Purchase Transaction Date',
        Cell: props => (
          <span>{formatters.date(props.original.transaction_date)}</span>
        ),
      },
      { Header: 'Transaction ID', accessor: 'transaction_batch_id' },
      { Header: 'HCP ID', accessor: 'hcp_id', show: (DASHBOARD_TYPE === 'mycare') },
      { Header: `${DASHBOARD_TYPE === 'mycare' ? 'HCP' : 'Doctor'} Name`, accessor: 'hcp_name' },
      { Header: `${DASHBOARD_TYPE === 'mycare' ? `DKSH` : `Zuellig` } Number`, accessor: `organization_code`},
      { Header: `${DASHBOARD_TYPE === 'mycare' ? 'Outlet Name' : 'Clinic Name'}`, accessor: 'organization_branch' },
      { Header: 'Prescribing Physician', accessor: 'prescribing_physician', show: (DASHBOARD_TYPE === 'mycare') },
      { Header: `Prescribing Physician's Clinic`, 
      Cell: props => (
        <span>{props.original.prescribing_clinic_name || '--'}</span>
      ),
       show: (DASHBOARD_TYPE === 'mycare') },
      { Header: 'Patient ID', accessor: 'patient_id' },
      { Header: 'Promo Code Used', accessor: 'promo_code' },
      { Header: 'Product', accessor: 'sku', show: (DASHBOARD_TYPE === 'mycare') },
      { Header: 'Quantity', accessor: 'quantity', show: (DASHBOARD_TYPE === 'mycare') },
      { Header: 'Meditag Number', accessor: 'meditag' },
      {
        Header: 'Protected Link to Image',
        Cell: props => (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() =>
              this._openImageModal(
                props.original.transaction_batch_id,
                props.original.transaction_campaign_id
              )
            }
          >
            View Image
          </Button>
        ),
        show: (DASHBOARD_TYPE === 'mycare'),
      },
    ],
  };

  async componentDidMount() {
    this.setState({ previousPath: this.props.location.pathname });
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date ||
      prevProps.filterStore.filters.doctor !== this.props.filterStore.filters.doctor
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;
    const { meditag } = this.state;
    let params = queryString.parse(this.props.history.location.search) || {};
    params.meditag ? this.setState({ meditag: params.meditag }) : this.setState({ meditag: '' });
    await this.props.getProductList({
      ...params,
      start_date,
      end_date,
      campaign_type: 'purchase',
      doctor
    });
    if (
      params.page <= this.props.transactionStore.purchasedProducts.pages &&
      params.page > 0
    ) {
      this.setState({ currentPage: params.page || 1 });
    } else {
      this.props.history.push(`${pathname}?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}${meditag && '&meditag='+meditag}`);
    }
  };

  _openExportModal = () => {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const params = queryString.parse(this.props.history.location.search) || {};
    this.props.showPopupModal({
      title: 'Purchased Products',
      message: 'Are you sure you want to export this table?',
      actions: [{
        label: 'Export',
        onClick: async () => {
          await this.props.exportProductList({
            start_date,
            end_date,
            campaign_type: 'purchase',
            meditag: params.meditag || null,
            doctor
          });
          this.props.closePopupModal();
        }
      }],
    })
  }

  _onSearch = e => {
    e.preventDefault();
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;
    const { meditag } = this.state;
    this.props.history.push(`${pathname}?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}${meditag && '&meditag='+meditag}`);
  }

  _openImageModal = async (transaction_batch_id, transaction_campaign_id) => {
    await this.props.getTransactionCampaignHologramImage({
      transaction_batch_id,
      transaction_campaign_id,
    });
    const { hologramImageURL } = this.props.transactionStore;
    this.props.showPopupModal({
      title: `Transaction ID: ${transaction_batch_id}`,
      message: <img style={{ width: '60%', margin: '0 auto', display: 'block' }} src={hologramImageURL} />,
      actions: [{
        label: 'Download Image',
        onClick: () => {
          const link = document.createElement('a');
          link.href = hologramImageURL;
          link.setAttribute('download', `${hologramImageURL.split('/').pop()}.jpeg`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }],
    })
  }

  render() {
    const { columns, currentPage, meditag } = this.state;

    return (
      <DataCard
        title="Purchased Products"
        style={{ background: '#8b1b3f', height: '100%' }}
        onExport={() => this._openExportModal()}
        dataCount={this.props.transactionStore.purchasedProducts.count}
        onSearch={{
          placeholder: 'Search meditag',
          defaultValue: meditag,
          onInputChange: e => this.setState({ meditag: e.target.value }),
          onSubmit: e => this._onSearch(e),
          inputProps: { maxLength: DASHBOARD_TYPE === 'mycare' ? 20 : 12 }
        }}
      >
        <PaginatedTable
          data={this.props.transactionStore.purchasedProducts.list}
          columns={columns}
          totalPages={this.props.transactionStore.purchasedProducts.pages}
          currentPage={currentPage}
          history={this.props.history}
        />
      </DataCard>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({
  transactionStore,
  filterStore,
  uiStore,
}) => {
  return {
    ...transactionStore,
    ...filterStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(PurchasedProductPage);
