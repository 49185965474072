import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

// Components
import { SummaryCard, DataCard } from '../ui/Card';
import AggregatedValue from '../ui/AggregatedValue';
import { Grid, TableRow, TableCell } from '@material-ui/core';
import { PieChart, LineGraph, MultiLineGraph} from '../ui/Chart';
import Table from '../ui/Table';

// Actions
import { 
    getProductSummary, getBrandMix, 
    getMonthlyTransactions, getTransactionFeed, getRetailerRebateAndBoxes
} from '../../actions/product';

class ProductPage extends React.Component {
    interval = null;
    componentDidMount() {
        this.getProductSummary();
        this.getBrandMix();
        this.getMonthlyTransactions();
        this.getTransactionFeed();
        this.getRebateAndBoxes();
        // this.getBoxes();
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

    getProductSummary = () => {
        const { dispatch } = this.props;
        dispatch(getProductSummary());
    }

    getBrandMix = () => {
        const { dispatch } = this.props;
        dispatch(getBrandMix());
    }

    getMonthlyTransactions = () => {
        const { dispatch } = this.props;
        dispatch(getMonthlyTransactions());
    }

    getTransactionFeed = () => {
        const { dispatch } = this.props;
        const limit = 1000;
        const loadTime = 5; // in minutes
        
        dispatch(getTransactionFeed({ limit }));

        // Fetch values again after 5 minutes (depending on loadTime)
       this.interval = setInterval(() => {
            dispatch(getTransactionFeed({ limit }));
        }, loadTime * 60000);
    }

    getRebateAndBoxes = () => {
        const {dispatch} = this.props;
        dispatch(getRetailerRebateAndBoxes());
    }

    getTableColumns = () => [
        'Id',
        'Date',
        'Organization',
        'Sold By',
        'Product',
        'Qty (in Boxes)'
    ];

    render() {
        const { product, ui, hcp } = this.props;
        const { 
            totalQuantitySold, 
            totalTransactionBatches, 
            totalTransactionCampaigns, 
            averageQuantity, 
            brandMix, 
            monthlyTransactions, 
            transactionFeed,
            retailerRebate
        } = product;

        const totalTransactingHCPs = hcp.totalTransactingHCPs;

        return <div>
            <Grid 
                container 
                direction='row' 
                alignItems='center' 
                justify='space-between' 
                spacing={16}>
                <Grid item xs={12}>
                    <SummaryCard 
                        title='Product Summary'
                        subtitle='General information'>
                        
                        <AggregatedValue 
                            md={6}
                            value={totalQuantitySold && totalQuantitySold.box}
                            valueInBoxes={true}
                            description='Total Boxes Sold'
                            isLoading={ui.productSummary.isLoading} />

                        <AggregatedValue 
                            md={6}
                            value={averageQuantity.box}
                            valueInBoxes={true}
                            description='Average Quantity (in boxes)'
                            tooltip='Average quantity sold per transaction'
                            isLoading={ui.productSummary.isLoading} />

                        <AggregatedValue 
                            md={6}
                            value={totalQuantitySold && totalQuantitySold.strips}
                            valueInBoxes={true}
                            description='Total Strips Sold'
                            isLoading={ui.productSummary.isLoading} />

                        <AggregatedValue 
                            md={6}
                            value={averageQuantity.strip}
                            valueInBoxes={true}
                            description='Average Quantity (in strips)'
                            tooltip='Average quantity sold per transaction'
                            isLoading={ui.productSummary.isLoading} />
                        

                        <AggregatedValue 
                            md={6}
                            value={totalTransactionBatches} 
                            description='Total Transaction Batches'
                            isLoading={ui.productSummary.isLoading} />

                        <AggregatedValue 
                            md={6}
                            value={totalTransactionCampaigns} 
                            description='Total Transaction Campaigns'
                            isLoading={ui.productSummary.isLoading} />

                        <AggregatedValue 
                            md={6}
                            value={totalTransactingHCPs} 
                            description='Total Transacting HCPs'
                            tooltip='Transacting Health Care Professionals, both Pharmacies and Physicians'
                            isLoading={ui.productSummary.isLoading} />
                    </SummaryCard>
                </Grid>
                
                <Grid item xs={12}>
                    <DataCard
                        title='Brand Mix'
                        subtitle='Product Segmentation, based on Quantity Sold (in boxes)'>
                        <PieChart 
                            isLoading={ui.brandMix.isLoading}
                            series={brandMix}
                            height={250} />
                    </DataCard>
                </Grid>

                <Grid item xs={12}>
                    <DataCard
                        title='Timegraph'
                        subtitle='Total Quantity Sold (in boxes) monthly'
                        isLoading={ui.monthlyQuantitySold.isLoading}>
                        <LineGraph
                            series={monthlyTransactions}
                            yAxis='Total Boxes'
                            xAxis='Period' />
                    </DataCard>
                </Grid>

                <Grid item xs={12}>
                    <DataCard
                        title='Retailer Performance'
                        subtitle=''
                        isLoading={ui.retailerRebateAndBoxes.isLoading}>
                            <MultiLineGraph
                            series={retailerRebate}
                            yAxis='Box/Rebate'
                            xAxis='Month' />
                    </DataCard>
                </Grid>

                <Grid item xs={12}>
                    <DataCard
                        title='Transactions View'
                        subtitle='Product transaction feed (updated every 5 minutes)'
                        isLoading={ui.transactionFeed.isLoading}>
                       <Table
                            columns={this.getTableColumns()}
                            totalCount={transactionFeed.length}
                            rows={transactionFeed.map((row, index) => (
                                <TableRow key={index} hover>
                                    <TableCell>{row.transaction_id}</TableCell>
                                    <TableCell>{moment(row.transaction_date).format('YYYY-MM-DD, h:mma')}</TableCell>
                                    <TableCell>{row.organization_branch}</TableCell>
                                    <TableCell>{row.healthcare_professional}</TableCell>
                                    <TableCell>{row.product}</TableCell>
                                    <TableCell numeric>{row.quantity}</TableCell>
                                </TableRow>
                            ))} />
                    </DataCard>
                </Grid>
            </Grid>
        </div>;
    }
};

const mapStateToProps = state => ({
    product: state.product,
    ui: state.ui,
    hcp: state.hcp,
    filter: state.filter
});

export default connect(mapStateToProps)(ProductPage);